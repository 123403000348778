import { combineReducers } from "@reduxjs/toolkit";
import ModalAlertReducer from "./ModalAlertObj";
import AccessTokens from "./reducers/AccessTokens";
import ActiveCall from "./reducers/ActiveCall";
import ActiveMenu from "./reducers/ActiveMenu";
import CollapsedSideBar from "./reducers/CollapseSideBar";
import DialState from "./reducers/DialState";
import ExtensionDialState from "./reducers/ExtensionDialState";
import ExpandRightBar from "./reducers/ExpandRightBar";
import Language from "./reducers/Language";
import LoggedIn from "./reducers/LoggedIn";
import NotificationsCount from "./reducers/NotificationsCount";
import PhoneConnectState from "./reducers/PhoneConnectState";
import PhoneInstances from "./reducers/PhoneInstances";
import PhoneState from "./reducers/PhoneState";
import SelectFlow from "./reducers/SelectFlow";
import SelectedAccount from "./reducers/SelectedAccount";
import SelectedTicket from "./reducers/SelectedTicket";
import SipSettings from "./reducers/SipSettings";
import TicketUpdate from "./reducers/TicketUpdate";
import UpdateTables from "./reducers/UpdateTables";
import UserLocation from "./reducers/UserLocation";
import UserPermissions from "./reducers/UserPermissions";
import UserProfile from "./reducers/UserProfile";
import UserObj from "./reducers/userObj";
import ChatdeskFilters from "./reducers/ChatdeskFilters";
import ChatdeskSearch from "./reducers/ChatdeskSearch";
import SelectShop from "./reducers/SelectShop";

const reducers = combineReducers({
	Collapsed: CollapsedSideBar,
	ActiveMenu: ActiveMenu,
	selectedAccount: SelectedAccount,
	selectedTicket: SelectedTicket,
	expandRightBar: ExpandRightBar,
	LoggedIn: LoggedIn,
	User: UserObj,
	Profile: UserProfile,
	UpdateTicket: TicketUpdate,
	Phone: PhoneState,
	UpdateTables: UpdateTables,
	ModalAlertReducer: ModalAlertReducer,
	PhoneConnect: PhoneConnectState,
	ActiveCall: ActiveCall,
	GlobalDialState: DialState,
	ExtensionDialState: ExtensionDialState,
	PhoneInstances: PhoneInstances,
	NotificationsCount: NotificationsCount,
	UserLocation: UserLocation,
	UserPermissions: UserPermissions,
	FlowId: SelectFlow,
	Language: Language,
	Sip: SipSettings,
	AccessToken: AccessTokens,
	ChatdeskFilters: ChatdeskFilters,
	ChatdeskSearch: ChatdeskSearch,
	ShopId: SelectShop,
});

export default reducers;
