const fr = {
	bonga_copyright: `Bonga © ${new Date().getFullYear()}`,
	logout: "Se déconnecter",
	crm: "CRM",
	dashboard: "tableau de bord",
	greeting: "Bonjour ",
	welcome_back: "content de te revoir",
	stats: "Statistiques",
	accounts: "titre de compte",
	tickets: "des billets",
	analytics: "Analytique",
	csat: "CSAT",
	csat_surveys: "CSAT Enquêtes",
	surveys: "Enquêtes",
	survey: "enquête",
	// TODO:
	section: "Section",
	add_survey: "ajouter une enquête",
	edit_survey: "Modifier enquête",
	view_survey: "voir enquête",
	survey_added_successfully: "Enquête ajoutée avec succès",
	survey_updated_successfully: "enquête mise à jour avec succès",
	survey_deleted_successfully: "Enquête supprimée avec succès",
	create_and_manage_surveys: "Créer et gérer des enquêtes",
	// TODO : Change below to FR.
	multiple_options: "Multiple Options",
	responses: "Réponses",
	questions: "des questions",
	question: "Question",
	add_question: "Ajouter une question",
	edit_question: "Modifier la question",
	view_question: " vue_question",
	question_added_successfully: "question ajoutée avec succès",
	question_updated_successfully: "Question mise à jour avec succès",
	question_rating: "Évaluation des questions",
	add_question_rating: "ajouter question notation  ",
	add_csat: "ajouter CSAT",
	position: "Position",
	by_channels: "par chaînes ",
	help_desk: "bureau d'aide",
	admin: "Administrateur",
	crm_analytics: "CRM analytique",
	chatdesk_analytics: "enquête",
	chatdesk_overview: "présentation",
	nia_overview: "apercu de nia ",
	add_mention: "ajouter une mention",
	edit_mention: "Modifier la mention",
	add_keyword: "Ajouter un mot clé",
	edit_keyword: "Modifier le mot clé",
	hashtags: "hashtags",
	mentions: "mentionne",
	mention: "mentionner",
	keyword: "mot-clé",
	overview: "Overview",
	cm_overview: "crm overview",
	live_reports: "Rapports en direct",
	agent_reports: "Rapports des agents",
	voice_reports: "rapports vocaux",
	reports: "rapports",
	nia_dashboard: "Nia Tableau de bord",
	add_hashtag: "Add Hashtag",
	edit_hashtag: "Edit Hashtag",
	crm_overview: "CRM Overview",
	account_details: "{account_title} Details",
	ticket_details: "billet détail",
	sentiments_overtime: "sentiments au fil du temps",
	words_frequency: "worde fréquence",
	tweets_overtime: "tweete des heures supplémentaires",
	tweets: "Tweete",
	ticket: "billet",
	edit_ticket: "modifier le  billet",
	add_ticket: "ajouter un billet",
	delete_ticket: "supprimer le billet ",
	no_tickets: "non billets",
	account: "compte",
	leads: "conduit(e)",
	lead_source: "Source principale",
	details: "détail",
	ad: "Publicité",
	google: "Google",
	faqs: "FAQ",
	employee_referral: "Recommandation d'employés",
	lead_status: "Statut de plomb",
	lead_type: "Type de plomb",
	lead_types: "Types de plomb",
	add_lead_type: "Ajouter un type de plomb",
	edit_lead_type: "Modifier le type de plomb",
	delete_lead_type: "Supprimer le type de plomb",
	lead_type_details: "Détails du plomb",
	lead_type_templates_details: "Détails des modèles de type de plomb",
	lead_types_popover:
		"Créez des types de plomb pour catégoriser vos prospects et ajoutez des champs personnalisés à chaque type de plomb.",
	view_lead_type_access_denied:
		"Vous n'avez pas accès aux types de plomb. Veuillez contacter votre administrateur.",
	view_lead_access_denied:
		"Vous n'avez pas accès aux prospects. Veuillez contacter votre administrateur.",
	working: "Travail en cours",
	nurtured: "Nurtured",
	macros: "Macros",
	actions: "Actions",
	converted: "Converti",
	all_contacts: "Tous les contacts",
	contact_groups: "Groupes de contacts",
	contacts: "Contacts",
	view_contact: "Voir le contact",
	view_contact_access_denied:
		"Vous n'avez pas accès aux contacts. Veuillez contacter votre administrateur.",
	add_contact_access_denied:
		"Vous n'avez pas accès pour ajouter des contacts. Veuillez contacter votre administrateur.",
	active: "Actif",
	inactive: "Inactif",
	search: "Rechercher",
	total: "Total",
	ticket_logs: "Logs de ticket",
	new: "Nouveau",
	take_break: "Prendre une pause",
	my_breaks: "Mes pauses",
	download: "Télécharger",
	download_leads_csv: "Télécharger les prospects au format CSV",
	download_leads: "Télécharger les prospects",
	download_products_CSV: "Télécharger les produits au format CSV",
	download_products: "Télécharger les produits",
	pick_up_where_you_left_off: "Reprenez là où vous vous êtes arrêté",
	take_a_shortcut_to_tasks: "Prenez un raccourci vers les tâches",
	add_a_task_from_here: "Ajoutez une tâche d'ici",
	take_a_shortcut_to_adding_events:
		"Prenez un raccourci pour ajouter des événements",
	add_an_event_from_here: "Ajoutez un événement d'ici",
	start_with_something_new: "Commencez quelque chose de nouveau...",
	take_a_shortcut_to_deals_won_overtime:
		"Prenez un raccourci vers les offres remportées au fil du temps",
	check_analysis_from_here: "Consultez l'analyse d'ici",
	check_analysis: "Vérifier l'analyse",
	get_started_with_bonga_crm: "Commencez avec Bonga CRM",
	create_a_new: "Créez un nouveau compte, contact, offre ou prospect....",
	break: "Pause",
	lead_contact: "Contact du prospect",
	select_reason: "Sélectionnez une raison",
	lunch: "Déjeuner",
	tea: "Thé",
	health: "Santé",
	other: "Autre",
	team_chat: "Chat d'équipe",
	cancel: "Annuler",
	save: "Enregistrer",
	run: "Exécuter",
	button: "Bouton",
	agents_online: "Agents en ligne",
	directory: "Annuaire",
	delete_social: "Supprimer le social",
	monitor_calls: "Surveiller les appels",
	call_module: "Module d'appels",
	dialer: "Composeur",
	returning: "Retour",
	new_leads: "Nouveaux prospects",
	returning_leads: "Prospects revenants",
	new_contacts: "Nouveaux contacts",
	returning_contacts: "Contacts revenants",
	new_accounts: "Nouveaux comptes",
	returning_accounts: "Comptes revenants",
	add_new_account: "Ajouter un nouveau compte {account_title}",
	add_new_lead: "Ajouter un nouveau prospect",
	add_new_contact: "Ajouter un nouveau contact",
	add_new_document: "Ajouter un nouveau document",
	add_new_task: "Ajouter une nouvelle tâche",
	add_new_task_stage: "Ajouter une nouvelle étape de tâche",
	add_new_product: "Ajouter un nouveau produit",
	add_new_deal: "Ajouter une nouvelle offre",
	edit_deal: "Modifier l'offre",
	sort_by: "Trier par",
	edit_task: "Modifier la tâche",
	filter_by: "Filtrer par",
	delete_account: "Supprimer {account_title}",
	phone_feature_under_development:
		"Fonctionnalité de téléphone en cours de développement",
	on_development_description:
		"Cette fonctionnalité est en cours de développement. Merci de revenir plus tard.",
	chat_feature_under_development:
		"Fonctionnalité de chat en cours de développement",
	my_dashboard: "Mon tableau de bord",
	deals_won_overTime: "Offres remportées au fil du temps",
	inbound_outbound: "Entrant/Sortant",
	welcome_to_crm: "Bienvenue dans le CRM",
	upgrade_crm_to_access_premium_statistics:
		"Mettez à niveau le CRM pour accéder aux statistiques premium",
	products: "Produits",
	product: "Produit",
	remarks: "Remarques",
	users: "Utilisateurs",
	notifications: "Notifications",
	no_notifications: "Pas de notifications",
	no_notifications_description:
		"Vous n'avez pas encore de notifications. Toutes vos nouvelles notifications apparaîtront ici.",
	no_read_notifications_description:
		"Vous n'avez pas de notifications dans cette catégorie.",
	add_product: "Ajouter un produit",
	add_lead_status: "Ajouter un statut de plomb",
	edit_lead: "Modifier le prospect",
	delete_lead: "Supprimer le prospect",
	add_alt_number: "Ajouter un autre numéro",
	edit_contact: "Modifier le contact",
	delete_contact: "Supprimer le contact",
	edit_account: "Modifier le compte",
	view_account_access_denied:
		"Vous n'avez pas accès aux comptes. Veuillez contacter votre administrateur.",
	delete_product: "Supprimer le produit",
	edit_product: "Modifier le produit",
	view_product_access_denied:
		"Vous n'avez pas accès aux produits. Veuillez contacter votre administrateur.",
	edit_lead_status: "Modifier le statut du prospect",
	lead_updated_successfully: "Prospect mis à jour avec succès",
	add_lead_rating_label: "Ajouter une étiquette de notation pour le prospect",
	edit_lead_rating_label: "Modifier l'étiquette de notation du prospect",
	add_deal_currency: "Ajouter une devise d'offre",
	edit_deal_currency: "Modifier la devise d'offre",
	add_deal_stage_status: "Ajouter un statut de phase d'offre",
	edit_deal_stage_status: "Modifier le statut de phase d'offre",
	login: "Se connecter",
	reset_password: "Réinitialiser le mot de passe",
	enter_email: "Veuillez saisir votre adresse e-mail de compte",
	reset_password_description:
		"Saisissez votre adresse e-mail pour réinitialiser votre mot de passe",
	check_email: "Vérifiez votre e-mail",
	check_email_description:
		"Un e-mail avec un mot de passe temporaire vous sera envoyé dans votre boîte de réception. Veuillez réinitialiser votre mot de passe après vous être connecté.",
	failed_to_send_email: "Échec de l'envoi de l'e-mail",
	add_account: "Ajouter {account_title} (facultatif)",
	event_account_description:
		"Sélectionnez un {account_title} à associer à cet événement.",
	add_guests_from_contacts:
		"Invitez des invités parmi vos contacts et collègues à cet événement.",
	deals: "Offres",
	types: "Types",
	profile: "Profil",
	settings: "Paramètres",
	personal_settings: "Paramètres personnels",
	profile_settings: "Paramètres du profil",
	manage_password: "Gérer le mot de passe",
	manage_calls: "Gérer les appels",
	manage_crm: "Gérer le CRM",
	manage_chatdesk: "Gérer Chatdesk",
	manage_profile: "Gérer le profil",
	manage_business_rules: "Gérer les règles métier",
	manage_org_details: "Gérer les détails de l'organisation",
	manage_branches: "Gérer les succursales",
	manage_users: "Gérer les utilisateurs",
	manage_roles: "Gérer les rôles",
	manage_role: "Gérer le rôle",
	manage: "Gérer {item}",
	feature: "Fonctionnalité",
	view: "Afficher",
	change: "Modifier",
	create: "Créer",
	roles: "Rôles",
	assign_user_role: "Attribuer un rôle à l'utilisateur",
	user_roles_assigned_successfully: "Rôles utilisateur attribués avec succès",
	user_roles: "Rôles utilisateur",
	permissions: "Permissions",
	click_to_edit: "Cliquez pour modifier la date et l'heure",
	time: "Heure",
	week: "Semaine",
	calendar: "Calendrier",
	contact: "Contact",
	next_week: "Semaine suivante",
	previous_week: "Semaine précédente",
	event: "Événement",
	day: "Jour",
	add_event: "Ajouter un événement",
	edit_event: "Modifier l'événement",
	add_event_description: "Ajouter une description de l'événement",
	add_event_title: "Ajouter un titre d'événement",
	add_guests: "Ajouter des invités",
	end_of_scroll: "Plus de résultats... 📃",
	start_of_scroll: "Recherche de tickets en cours... 🔍",
	summary: "Résumé",
	reply_with_macro: "Répondre avec un macro",
	reply_with: "Répondre avec",
	add_category: "Ajouter une catégorie",
	view_category: "Voir la catégorie",
	edit_category: "Modifier la catégorie",
	delete_category: "Supprimer la catégorie",
	manage_categories_access_denied:
		"Vous n'avez pas accès à la gestion des catégories",
	status: "Statut",
	statuses: "Statuts",
	role: "Rôle",
	add_role: "Ajouter un rôle",
	edit_role: "Modifier le rôle",
	view_role: "Voir le rôle",
	delete_role: "Supprimer le rôle",
	role_added_successfully: "Rôle ajouté avec succès",
	updated_successfully: "Mise à jour réussie",
	add_status: "Ajouter des statuts",
	view_status: "Voir les statuts",
	edit_status: "Modifier les statuts",
	delete_status: "Supprimer les statuts",
	add_to_list: "Ajouter à la liste",
	edit_date: "Modifier la date",
	channels: "Canaux",
	add_channel: "Ajouter un canal",
	view_channel: "Voir le canal",
	edit_channel: "Modifier le canal",
	delete_channel: "Supprimer le canal",
	upload_file: "Téléverser un fichier",
	upload_description:
		"Pour de meilleurs résultats, veuillez télécharger un fichier WAV mono 16 bits 8 kHz/16 kHz.",
	date_created: "Date de création",
	click_or_drag_file_to_this_area_to_upload:
		"Cliquez ou faites glisser un fichier dans cette zone pour le télécharger",
	click_to_add_file: "Cliquez pour ajouter un fichier",
	priorities: "Priorités",
	add_priority: "Ajouter des priorités",
	view_priority: "Voir les priorités",
	edit_priority: "Modifier les priorités",
	delete_priority: "Supprimer les priorités",
	services: "Services",
	add_service: "Ajouter un service",
	view_service: "Voir le service",
	edit_service: "Modifier le service",
	delete_service: "Supprimer le service",
	clients: "Clients",
	add_client: "Ajouter un client",
	view_client: "Voir le client",
	edit_client: "Modifier le client",
	add_socials: "Ajouter des réseaux sociaux",
	socials: "Réseaux sociaux",
	add_social: "Ajouter un réseau social",
	social_handle: "Identifiant social",
	handle: "Identifiant",
	display_name: "Nom d'affichage",
	delete_client: "Supprimer le client",
	agents: "Agents",
	platform: "Plateforme",
	to: "À",
	adding_social: "Ajout de l'identifiant social",
	from: "De",
	sending: "Envoi en cours...",
	email_placeholder: "Rédigez votre e-mail ici",
	add_label: "Ajouter une étiquette",
	add_new_label: "Ajouter une nouvelle étiquette",
	add_agent: "Ajouter un agent",
	view_agent: "Voir l'agent",
	edit_agent: "Modifier l'agent",
	delete_agent: "Supprimer l'agent",
	view_macros: "Voir les macros",
	edit_macros: "Modifier les macros",
	delete_macros: "Supprimer les macros",
	delete_confirm: "Êtes-vous sûr de vouloir supprimer cet élément",
	delete_success: "Élément supprimé avec succès",
	delete_error: "Erreur de suppression de l'élément",
	assign_agent: "Affecter un agent",
	assign_agent_role: "Affecter un rôle à l'agent",
	add_macros: "Ajouter des macros",
	add_macro: "Ajouter un macro",
	view_macro: "Voir le macro",
	edit_macro: "Modifier le macro",
	delete_macro: "Supprimer le macro",
	message_template: "Modèle de message",
	add_message_template: "Ajouter un modèle",
	edit_message_template: "Modifier le modèle",
	new_template_body_placeholder: "Le corps du message va ici",
	new_template_header_placeholder: "Le texte de l'en-tête va ici",
	new_template_footer_placeholder: "Le texte du pied de page va ici",
	apps: "Applications",
	send_email: "Envoyer un e-mail",
	inbox: "Boîte de réception",
	sent: "Envoyé",
	close: "Fermer",
	sync_with_google: "Synchroniser avec Google",
	use_without_google: "Utiliser sans Google",
	account_not_synced: "Compte non synchronisé",
	account_not_synced_description:
		"Votre compte n'est pas synchronisé avec Google. Veuillez synchroniser votre compte pour accéder à vos e-mails, événements et contacts.",
	gmail_auth_response: "Le jeton n'existe pas, veuillez vous authentifier",
	drafts: "Brouillons",
	trash: "Corbeille",
	compose: "Composer",
	update: "Mettre à jour",
	not_found:
		"Cette page semble ne pas exister !.\n Veuillez vérifier l'URL et réessayer.",
	page_moved: "Page déplacée",
	page_moved_description:
		"Cette page a été déplacée vers un nouvel emplacement. Veuillez cliquer sur le bouton ci-dessous pour accéder à la nouvelle page.",
	go_to_new_page: "Aller à la nouvelle page",
	latest: "Dernier",
	back: "Retour",
	back_home: "Retour à l'accueil",
	update_success: "Élément mis à jour avec succès",
	update_error: "Erreur lors de la mise à jour de l'élément",
	links: "Liens",
	linked_event: "Événement lié",
	linked_deal: "Offre liée",
	linked_lead: "Prospect lié",
	add_links: "Ajouter des liens",
	view_action: "Voir l'action",
	edit_action: "Modifier l'action",
	delete_action: "Supprimer l'action",
	add_action: "Ajouter une action",
	action_type: "Type d'action",
	update_action_success: "Action mise à jour avec succès",
	add_action_success: "Action ajoutée avec succès",
	view_action_access_denied:
		"Vous n'avez pas accès à la visualisation des actions. Veuillez contacter votre administrateur",
	add_faq: "Ajouter une FAQ",
	view_faq: "Voir la FAQ",
	edit_faq: "Modifier la FAQ",
	hashtag: "Hashtag",
	delete_faq: "Supprimer la FAQ",
	delete: "Supprimer",
	action: "Action",
	macro: "Macro",
	faq: "FAQ",
	client: "Client",
	agent: "Agent",
	priority: "Priorité",
	low: "Faible",
	high: "Élevée",
	urgent: "Urgente",
	service: "Service",
	channel: "Canal",
	category: "Catégorie",
	categories: "Catégories",
	delete_description: "Êtes-vous sûr de vouloir supprimer cet élément ?",
	delete_disclaimer: "Cette action est irréversible.",
	signup: "S'inscrire",
	login_text:
		"Bienvenue sur Bonga, connectez-vous à votre compte pour continuer à utiliser nos services",
	signup_text:
		"Bienvenue sur Bonga, créez un compte pour accéder à nos services",
	forgot_password: "Mot de passe oublié ?",
	already_a_user: "Vous avez déjà un compte ?",
	account_name: "Nom de {account_title}",
	account_type: "Type de {account_title}",
	rating: "Évaluation",
	// TODO:
	rating_icon: "Rating icon",
	content: "Contenu",
	macro1: "Macro 1",
	macro2: "Macro 2",
	available_for: "Disponible pour",
	all_agents: "Tous les agents",
	me_only: "Moi seulement",
	agents_in_group: "Agents dans le groupe",
	no_of_employees: "Nombre d'employés",
	description: "Description",
	organization: "Organisation",
	account_owner: "Propriétaire de {account_title}",
	mobile: "Mobile",
	password: "Mot de passe",
	confirm_password: "Confirmer le mot de passe",
	old_password: "Ancien mot de passe",
	new_password: "Nouveau mot de passe",
	enter_new_password: "Entrez le nouveau mot de passe",
	enter_old_password: "Entrez l'ancien mot de passe",
	update_password: "Mettre à jour le mot de passe",
	password_dosent_match: "Le mot de passe ne correspond pas",
	password_dosent_match_description:
		"Les mots de passe que vous avez saisis ne correspondent pas. Veuillez confirmer le mot de passe pour continuer.",
	contact_name: "Nom du contact",
	title: "Titre",
	repeat: "Répéter",
	sub_tasks: "Sous-tâches",
	add_sub_task: "Ajouter une sous-tâche",
	edit_sub_task: "Modifier la sous-tâche",
	first_name: "Prénom",
	last_name: "Nom de famille",
	company: "Société",
	email: "E-mail",
	extension: "Extension",
	username: "Nom d'utilisateur",
	add_user: "Ajouter un utilisateur",
	edit_user: "Modifier l'utilisateur",
	deactivate_user: "Désactiver l'utilisateur",
	activate_user: "Activer l'utilisateur",
	deactivate_user_description:
		"Êtes-vous sûr de vouloir désactiver cet utilisateur ?",
	activate_user_description:
		"Êtes-vous sûr de vouloir activer cet utilisateur ?",
	delete_user: "Supprimer l'utilisateur",
	delete_user_description:
		"Êtes-vous sûr de vouloir supprimer cet utilisateur ?",
	view_user: "Voir l'utilisateur",
	name: "Nom",
	option: "Option",
	// TODO:
	question_options: "Question Options",
	required: "Required",
	todo: "À faire",
	in_progress: "En cours",
	done: "Terminé",
	add: "Ajouter",
	group_name: "Nom du groupe",
	select_contacts_below: "Sélectionnez des contacts ci-dessous",
	add_group: "Ajouter un groupe",
	group_added_successfully: "Groupe ajouté avec succès",
	group_updated_successfully: "Groupe mis à jour avec succès",
	group_deleted_successfully: "Groupe supprimé avec succès",
	group_delete_confirmation: "Êtes-vous sûr de vouloir supprimer ce groupe ?",
	group_delete_disclaimer: "Cette action est irréversible.",
	group_delete_error: "Erreur lors de la suppression du groupe",
	change_password: "Changer le mot de passe",
	type: "Type",
	price: "Prix",
	phone_number: "Numéro de téléphone",
	enter_phone_numbers: "Saisir les Numéros de Téléphone",
	lead_owner: "Propriétaire du prospect",
	department: "Département",
	add_department: "Détails du départementt",
	edit_department: "Département d’édition",
	updated_department_successfully: "Département mis à jour avec succès",
	error_adding_department: "Erreur lors de l’ajout d’un service",
	added_department_successfully: "Ajout d’un département avec succès",
	select_branch: "Sélectionner une branche",
	error_branch_required: "branche d’erreur requise",
	department_details: "Département ",
	created_by: "Créé par",
	product_owner: "Créé par",
	product_code: "Code du produit",
	valid_till: "Valide jusqu'au",
	subject: "Sujet",
	due_date: "Date d'échéance",
	closed_by: "Fermé par",
	currency: "Devise",
	product_type: "Type de produit",
	product_category: "Catégorie de produit",
	add_product_type: "Ajouter un type de produit",
	edit_product_type: "Modifier le type de produit",
	add_product_category: "Ajouter une catégorie de produit",
	edit_product_category: "Modifier la catégorie de produit",
	category_name: "Nom de la catégorie",
	product_type_name: "Nom du type de produit",
	success: "Succès",
	product_type_updated_successfully: "Type de produit mis à jour avec succès",
	product_category_updated_successfully:
		"Catégorie de produit mise à jour avec succès",
	queue_added_successfuly: "La file d'attente a été ajoutée avec succès",
	menu_added_successfully: "Menu IVR ajouté avec succès",
	menu_option_added_successfully: "Option de menu IVR ajoutée avec succès",
	ext_added_successfully: "Extension ajoutée avec succès",
	ext_upload_successfully: "Extensions téléchargées avec succès",
	add_menu_option: "Ajouter une option de menu",
	ivr_menu: "Menu IVR",
	edit_menu: "Modifier le menu IVR",
	delete_menu: "Supprimer le menu IVR",
	recording_added_successfuly: "L'enregistrement a été ajouté avec succès",
	moh_added_successfully: "La musique en attente a été ajoutée avec succès",
	moh: "Musique en attente",
	add_moh: "Ajouter une musique en attente",
	edit_moh: "Modifier la musique en attente",
	delete_moh: "Supprimer la musique en attente",
	call_settings: "Paramètres d'appel",
	destinations: "Destinations",
	destination_added_successfully: "La destination a été ajoutée avec succès",
	add_destination: "Ajouter une destination",
	edit_destination: "Modifier la destination",
	delete_destination: "Supprimer la destination",
	please_upload_file: "Veuillez télécharger un fichier",
	upload_audio_file: "Télécharger un fichier audio",
	close_date: "Date de clôture",
	loss_reason: "Raison de la perte",
	label: "Étiquette",
	link_a_lead: "Lier un prospect",
	link_a_contact: "Lier un contact",

	link_a_deal: "Lier une affaire",
	link_a_product: "Lier un produit",
	link_a_ticket: "Lier un ticket",
	link_an_event: "Lier un événement",
	stage: "Étape",
	stage_status: "Statut de l'étape",
	delete_task: "Supprimer la tâche",
	amount: "Montant",
	assigned_to: "Assigné à",
	no_accounts:
		"{account_title} apparaîtra ici une fois que vous en aurez créé un",
	no_calls:
		"Les appels récents apparaîtront ici une fois que vous en aurez passé un",
	recent_calls: "Appels récents",
	inbound_calls: "Appels entrants",
	inbound: "Entrants",
	outbound: "Sortants",
	inbound_calls_by_day: "Appels entrants par jour",
	inbound_overview: "Aperçu des appels entrants",
	outbound_overview: "Aperçu des appels sortants",
	inbound_calls_received_by_agents: "Appels entrants reçus par les agents",
	outbound_calls_received_by_agents: "Appels sortants reçus par les agents",
	inbound_calls_per_hour: "Appels entrants par heure",
	outbound_calls_per_hour: "Appels sortants par heure",
	inbound_calls_by_status: "Appels entrants par statut",
	outbound_calls_by_status: "Appels sortants par statut",
	calls_by_day: "Appels par jour",
	calls_by_agents: "Appels par agents",
	calls_per_hour: "Appels par heure",
	to_do: "À faire",
	add_task: "Ajouter une tâche",
	incomplete_tasks: "Tâches incomplètes",
	completed_taskks: "Tâches terminées",
	view_task_access_denied:
		"Vous n'avez pas accès à la visualisation des tâches. Veuillez contacter votre administrateur",
	inbound_calls_by_date: "Appels entrants par date",
	deals_created_per_month: "Affaires créées par mois",
	leads_converted_per_month: "Prospects convertis par mois",
	inbound_by_month_and_call_status: "Entrants par mois et statut de l'appel",
	outbound_by_month_and_call_status: "Sortants par mois et statut de l'appel",
	inbound_outbound_agents:
		"Statistiques des appels entrants et sortants par agents",
	talk_ring_cost_agents: "Statistiques de temps moyen par agents",
	user_stats: "Statistiques des utilisateurs",
	nia_stats: "Statistiques Nia",
	overall_analytics: "Analytique global",
	ticketing_video:
		"Voici une vidéo pour naviguer dans notre système de gestion des tickets !",
	crm_video: "Voici une vidéo pour naviguer dans notre système de CRM !",
	nia_video: "Voici une vidéo pour naviguer dans Nia !",
	call_video: "Voici une vidéo pour naviguer dans notre module d'appels !",
	tweet_impression: "Impressions de tweets par mois",
	tweets_impression_overtime:
		"Mise à jour en temps réel de l'impression des tweets",
	tweet_engagement: "Analyse de l'engagement des tweets",
	total_agents: "Nombre total d'agents",
	top_callmaker: "Meilleur appelant",
	top_callreceiver: "Meilleur receveur d'appels",
	agent_performance: "Performance des agents",
	total_outbound_calls: "Total des appels sortants",
	total_callcost: "Coût total des appels",
	total_inbound_calls: "Total des appels entrants",
	unresolved_tickets: "Tickets non résolus",
	my_tickets_by_status: "Mes tickets par statut",
	tickets_escalated: "Tickets escaladés vers vous",
	export: "Exporter",
	outbound_calls_by_date: "Appels sortants par date",
	outbound_calls: "Appels sortants",
	account_leads: "Prospects {account_title}",
	account_contacts: "Contacts {account_title}",
	account_deals: "Affaires {account_title}",
	account_tickets: "Tickets {account_title}",
	leads_generated_by_month: "Prospects générés par mois",
	graph_one_title: "Statut du ticket par compte",
	graph_two_title: "Moyenne de tickets créés par jour de la semaine",
	graph_three_title: "Pipeline des tickets par date",
	pipeline_trend: "Tendance du pipeline",
	graph_four_title: "Par statut de ticket",
	customer_satisfaction: "Satisfaction du client",
	tickets_subject: "Objet des tickets",
	select_client: "Sélectionner le client",
	select_contact: "Sélectionner le contact",
	select_agent: "Sélectionner l'agent",
	select_agents: "Sélectionner les agents",
	select_category: "Sélectionner la catégorie",
	select_priority: "Sélectionner la priorité",
	select_status: "Sélectionner le statut",
	select_type: "Sélectionner le type",
	select_channel: "Sélectionner le canal",
	select_service: "Sélectionner le service",
	select_macro: "Sélectionner la macro",
	select_action: "Sélectionner l'action",
	select_source: "Sélectionner la source",
	select_account: "Sélectionner le compte",
	select_tags: "Sélectionner les tags",
	ticket_description: "Description du ticket",
	search_agent_group: "Rechercher le groupe d'agents",
	chat: "Discussion",
	client_details: "Détails du client",
	lorem_ipsum:
		"Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed euismod, nisl nec aliquam aliquam, nunc nisl aliquam nisl",
	via_twitter: "via Twitter",
	via: "via",
	twitter: "Twitter",
	facebook: "Facebook",
	linkedin: "LinkedIn",
	whatsapp: "WhatsApp",
	templates: "Modèles de messages WhatsApp",
	Messenger: "Messager",
	sms: "SMS",
	smss: "SMS",
	enter_sms: "Veuillez saisir votre message SMS ici",
	telegram: "Télégramme",
	preferred: "Préféré",
	client_account: "Compte {account_title}",
	account_products: "Produits {account_title}",
	add_to_account: "Ajouter à {account_title}",
	overdue: "En retard",
	open: "Ouvert",
	negative: "Négatif",
	sentiment: "Sentiment",
	location: "Emplacement",
	industry: "Industrie",
	activity_log: "Journal d'activité",
	no_logs: "Aucun journal trouvé",
	no_logs_description:
		"L'activité de l'utilisateur apparaîtra ici une fois qu'il aura effectué une action",
	ticket_created: "Ticket créé",
	conversation_with: "Conversation avec",
	email_from: "E-mail de",
	via_email: "via E-mail",
	agent_replied: "L'agent a répondu à",
	ticket_closed: "Ticket fermé",
	ticket_reopened: "Ticket réouvert",
	ticket_assigned: "Ticket assigné",
	agents_assigned: "Agents assignés",
	load_more: "Charger plus",
	see_all: "Tout voir",
	owner: "Propriétaire",
	employees: "Employés",
	events: "Événements",
	files: "Fichiers",
	integration: "Intégration",
	integrations: "Intégrations",
	add_integration: "Ajouter une intégration",
	edit_integration: "Modifier l'intégration",
	tag: "Tag",
	tags: "Tags",
	add_tag: "Ajouter un tag",
	view_tag: "Voir le tag",
	edit_tag: "Modifier le tag",
	delete_tag: "Supprimer le tag",
	emails: "E-mails",
	notes: "Notes",
	sources: "Sources",
	tasks: "Tâches",
	reminders: "Rappels",
	reminders_description: "Affichez et gérez vos rappels",
	add_reminder: "Ajouter un rappel",
	view_reminder: "Voir le rappel",
	edit_reminder: "Modifier le rappel",
	delete_reminder: "Supprimer le rappel",
	documents: "Documents",
	create_document: "Créer un document",
	document_editor: "Éditeur de documents",
	download_document: "Télécharger le document",
	create_and_manage_documents: "Créer et gérer des documents",
	send: "Envoyer",
	message: "Message",
	proceed: "Poursuivre",
	loading: "Chargement...",
	see_details: "Voir les détails",
	macro_details: "Détails de la macro",
	close_details: "Fermer les détails",
	write_message_here: "Écrivez le message ici...",
	lead_rating: "Évaluation du prospect",
	lead: "Prospect",
	deal: "Affaire",
	edit: "Modifier",
	lead_admin: "Administrateur de prospects",
	no_tweets: "Aucun tweet",
	closed_on: "Fermé le",
	get_started: "Commencer",
	get_started_for_free: "Commencer gratuitement",
	already_setup: "Vous avez déjà un compte ?",
	org_name: "Nom de l'entreprise",
	org_email: "E-mail de l'entreprise",
	add_your_org: "Ajoutez votre entreprise",
	free_plan: "Inscrivez-vous au plan de base gratuit",
	info: "Info",
	phone: "Téléphone",
	accept_call: "Accepter l'appel",
	decline_call: "Refuser l'appel",
	outgoing: "Sortant",
	incoming: "Entrant",
	business_login: "Utilisez vos identifiants d'agent pour vous connecter",
	free_plan_desc:
		"Vous pourrez accéder gratuitement aux modules complets et ne paierez que pour les modules et fonctionnalités premium au besoin.",
	add_your_org_description: "Ajoutez votre entreprise pour commencer",
	dont_have_an_account: "Vous n'avez pas de compte ?",
	link_ticket_to_account: "Êtes-vous sûr de vouloir lier ce ticket à",
	connect_phone: "Connecter le téléphone",
	disconnect_phone: "Déconnecter le téléphone",
	leads_statistics_by_month: "Statistiques des prospects par mois",
	upload_contacts: "Télécharger des contacts",
	upload_document: "Télécharger un document",
	clear_upload: "Effacer le téléchargement",
	download_contacts: "Télécharger les contacts",
	download_accounts_CSV: "Télécharger le CSV {account_title}",
	download_leads_CSV: "Télécharger les prospects en CSV",
	download_contacts_csv: "Télécharger les contacts en CSV",
	download_template: "Télécharger le modèle",
	contact_details: "Détails du contact",
	download_events: "Télécharger les événements",
	upload_products: "Télécharger les produits",
	download_products_template: "Télécharger le modèle de produits",
	download_deals_csv: "Télécharger les affaires en CSV",
	download_tasks: "Télécharger les tâches",

	download_users: "Télécharger les utilisateurs",
	download_contacts_template: "Télécharger le modèle de contacts",
	upload_leads: "Télécharger les prospects",
	download_leads_template: "Télécharger le modèle de prospects",
	download_deals: "Télécharger les affaires",
	shortcuts: "Raccourcis",
	source: "Source",
	clear_filters: "Effacer les filtres",
	no_results_to_show: "Aucun résultat à afficher",
	event_error: "Impossible de créer/éditer un événement dans le passé",
	team: "Équipe",
	teams: "Équipes",
	add_team: "Ajouter une équipe",
	edit_team: "Modifier une équipe",
	ticket_lifetime: "Durée de vie du ticket en minutes",
	manage_dids: "Gérer les DIDs",
	manage_exts: "Gérer les extensions",
	recharge_did: "Recharger le DID",
	view_did: "Voir les détails du DID",
	request_a_did: "Demander un DID",
	request_an_ext: "Demander une/des extension(s)",
	queue: "File d'attente",
	add_queue: "Ajouter une file d'attente",
	edit_queue: "Modifier la file d'attente",
	delete_queue: "Supprimer la file d'attente",
	queue_type: "Type de file d'attente",
	ring_strategy: "Stratégie de sonnerie",
	add_ivr_menu: "Ajouter un menu IVR",
	add_recording: "Ajouter un enregistrement",
	add_exts_to_queue: "Ajouter des extensions à la file d'attente",
	add_directory_ext: "Ajouter une extension de répertoire",
	edit_directory_ext: "Modifier l'extension de répertoire",
	delete_directory_ext: "Supprimer l'extension de répertoire",
	error: "Erreur",
	delete_card: "Supprimer la carte",
	add_card: "Ajouter une carte",
	add_payment_method: "Ajouter une méthode de paiement",
	set_as_default: "Définir comme défaut",
	payment_methods: "Méthodes de paiement",
	payment_methods_description: "Gérez vos méthodes de paiement",
	something_went_wrong: "Quelque chose s'est mal passé",
	add_option_destination: "Ajouter une destination d'option",
	remove_option_destination: "Supprimer la destination d'option",
	select_recording: "Veuillez sélectionner un enregistrement",
	select_queue: "Veuillez sélectionner une file d'attente",
	select_did: "Veuillez sélectionner un DID/N° de téléphone",
	ivr_menu_options: "Options de menu IVR",
	add_ivr_menu_option: "Ajouter une option",
	add_option: "Ajouter une option",
	select_menu_type: "Veuillez sélectionner le type de menu",
	table_view: "Vue en tableau",
	board_view: "Vue en tableau de bord",
	recording: "Enregistrement",
	no_recording_found: "Aucun enregistrement trouvé",
	task_stage: "Étape de la tâche",
	updated_task_stage_successfully: "Étape de la tâche mise à jour avec succès",
	edit_task_stage: "Modifier l'étape de la tâche",
	add_option_Destination: "Ajouter une destination d'option",
	remove_option: "Supprimer l'option",
	entry: "Entrée",
	entries: "Entrées",
	comment: "Commentaire",
	comments: "Commentaires",
	reply: "Réponse",
	replies: "Réponses",
	account_title: "Compte",
	accounts_title: "Comptes",
	forms: "{form_title}",
	add_new_form: "Ajouter un nouveau {form_title}",
	field: "Champ",
	field_type: "Type de champ",
	add_field: "Ajouter un champ {form_title}",
	edit_field: "Modifier un champ {form_title}",
	delete_field: "Supprimer un champ {form_title}",
	custom_fields: "{field} {number}",
	text: "Texte",
	textarea: "Zone de texte",
	checkbox: "Case à cocher",
	radio: "Bouton radio",
	select: "Sélectionner",
	number: "Numéro",
	date: "Date",
	website: "Site web",
	select_template_form: "Sélectionner le modèle de {form_title}",
	linked_task: "Tâche liée",
	link_ticket_to_task: "Lier le ticket à la tâche",
	no_linked_task: "Aucune tâche n'est liée à ce ticket",
	tasks_without_stage: "Tâches sans étape attribuée",
	by_agent: "Statut du ticket par agent",
	add_account_type: "Ajouter un type de {account_title}",
	edit_account_type: "Modifier le type de {account_title}",
	convert_lead_to_account: "Convertir le prospect en {account_title}",
	confirm_convert:
		"Êtes-vous sûr de vouloir convertir ce prospect en {account_title} ?",
	funnel: "Entonnoir",
	add_new: "Ajouter un(e) ",
	no_contact: "Aucun contact lié",
	my_report: "Mon rapport",
	overall_report: "Rapport global",
	preview: "Aperçu",
	view_comments: "Voir les commentaires",
	leads_without_status: "Prospects sans statut",
	leads_converted_to_account: "Prospects convertis en {account_title}",
	lead_already_converted: "Prospect déjà converti",
	disposition: "Disposition",
	call_disposition: "Disposition de l'appel",
	call_queue: "File d'attente d'appel",
	create_form: "Créer un(e) {form_title}",
	create_ticket: "Créer un ticket",
	create_task: "Créer une tâche",
	create_event: "Créer un événement",
	create_deal: "Créer une affaire",
	create_lead: "Créer un prospect",
	add_contact: "Ajouter un nouveau contact",
	my_inbound_report: "Mes appels entrants",
	my_outbound_report: "Mes appels sortants",
	my_missed_report: "Mes appels manqués",
	overall_answered_outbound: "Sortants répondus",
	overall_answered_inbound: "Entrants répondus",
	overall_dropped_ivr: "Abandonné à IVR",
	overall_dropped_queue: "Déposé dans la file d'attente",
	overall_answered_total: "Total répondus",
	overall_inbound: "Total des appels entrants",
	overall_outbound: "Total des appels sortants",
	overall_missed_inbound: "Manqués entrants",
	overall_missed_outbound: "Manqués sortants",
	overall_missed_total: "Total des appels manqués",
	overall_abandoned_total: "Total des appels abandonnés",
	overall_abandoned_inbound: "Abandonnés entrants",
	overall_abandoned_outbound: "Abandonnés sortants",
	activate: "Activer",
	deactivate: "Désactiver",
	activate_account: "Activer {account_title}",
	deactivate_account: "Désactiver {account_title}",
	deactivated_account_action:
		"Les actions disponibles sont l'activation et la suppression uniquement",
	confirm_activation: "Êtes-vous sûr de vouloir activer",
	confirm_deactivation: "Êtes-vous sûr de vouloir désactiver",
	live_calls: "Appels en direct",
	reload: "Recharger",
	soft_phone: "Téléphone logiciel",
	whisper: "Murmurer",
	spy: "Espionner",
	transfer: "Transférer",
	transfer_to_survey: "Transférer vers l'enquête",
	transfer_call: "Transférer l'appel",
	return_to_call: "Revenir à l'appel",
	view_transactions: "Voir les transactions",
	hold: "Mettre en attente",
	resume: "Reprendre",
	merge: "Fusionner",
	barge: "Envahir",
	make_call: "Passer un appel",
	call: "Appel {number}",
	access_denied: "Accès refusé",
	access_denied_message:
		"Vous n'avez pas la permission d'accéder à cette fonctionnalité",
	make_call_to: "Veuillez confirmer l'appel à {number}",
	alt_phone_number: "Numéro de téléphone alternatif",
	connected: "Connecté",
	disconnected: "Déconnecté",
	connected_message:
		"Votre téléphone est en ligne et peut recevoir et émettre des appels",
	call_on_hold: "En attente",
	call_on_hold_description: "Votre appel est en attente...",
	disconnected_message:
		"Votre téléphone est hors ligne et ne peut ni recevoir ni émettre d'appels",
	account_forms_empty:
		"Aucun(e) {form_title} disponible pour ce(te) {account_title}",
	form_details: "Détails du {form_title}",
	template_details: "Détails du modèle",
	currently_on_break: "Vous êtes actuellement en pause !",
	taken_break: "Vous avez pris une pause avec succès",
	failed_break: "Impossible de prendre une pause",
	resumed_from_break: "Vous avez repris votre travail avec succès",
	failed_resume_from_break: "Impossible de reprendre le travail après la pause",
	unread: "Non lu",
	view_details: "Voir les détails",
	view_activity_logs:
		"Voir tous les journaux d'activité et les filtrer en fonction de différents champs",
	accounts_popover:
		"{account_title} qui ont été créés ou convertis à partir de prospects",
	account_details_popover:
		"Gérez les détails des {account_title} sélectionnés tels que les événements, les affaires, les tâches, les documents, etc.",
	account_details_popover_leftbar:
		"Affichez les détails du {account_title} et la possibilité de les éditer, de les désactiver ou de les supprimer",
	account_definition:
		"Un {account_title} est une entreprise/organisation/individu avec laquelle une équipe de vente travaille ou qu'elle cible en tant que client potentiel. Ils sont associés à un ou plusieurs contacts que l'équipe de vente peut contacter",
	study_definition:
		"Une {account_title} est un projet de recherche spécifique ou une enquête menée sur le terrain. Les études peuvent être utilisées pour collecter des informations sur les préférences des clients, les tendances du marché ou d'autres facteurs susceptibles d'informer la stratégie de vente de l'entreprise",
	crm_funnel_details:
		"Fonctionnalités diverses telles que la gestion des prospects",
	crm_analytics_details:
		"Analyse des prospects, des affaires, des statistiques des agents, etc.",
	crm_admin_details:
		"Fonctionnalités d'administration pour la gestion du CRM, y compris les statuts des prospects, les évaluations, les devises et les statuts des affaires, les produits, les étapes des tâches, les modèles de formulaires, les types de comptes, etc.",
	crm_overview_details: "Aperçu du CRM, avec des actions rapides",
	lead_details:
		"Un prospect est une entreprise/un individu qui a manifesté de l'intérêt pour les produits et/ou services de l'entreprise",
	action_details: "Une action déclenche une action automatisée",
	deal_details:
		"Une affaire est une vente potentielle activement poursuivie par l'équipe de vente. Les affaires sont associées à un {account_title} spécifique",
	product_details:
		"Un produit est un article ou un service spécifique que l'entreprise vend à ses clients",
	task_details:
		"Une tâche est une action ou une activité spécifique à accomplir par un membre de l'équipe. Les tâches peuvent être associées à des événements, des prospects, des affaires, etc.",
	form_definition:
		"Un(e) {form_title} est un document ou un modèle numérique utilisé pour collecter et stocker des informations sur les prospects, les clients, les affaires, etc. Les formulaires permettent de standardiser la collecte de données et de s'assurer que les informations clés sont capturées de manière cohérente par les différents membres de l'équipe",
	lead_status_def:
		"Un statut de prospect est l'étape du processus de vente qu'un prospect particulier a atteinte. Les statuts de prospect courants comprennent 'nouveau', 'contacté', 'qualifié', 'proposition envoyée', 'gagné' et 'perdu'",
	lead_rating_def:
		"Une évaluation de prospect (également appelée notation de prospect) est une valeur numérique ou un score attribué à un prospect en fonction de son niveau d'engagement et d'intérêt pour les produits ou services de l'entreprise",
	deal_currency_def:
		"Une devise d'affaire est la devise dans laquelle une opportunité ou une affaire de vente est libellée. Lorsqu'une équipe de vente travaille avec des clients ou des prospects situés dans différents pays ou régions, il peut être nécessaire d'utiliser différentes devises pour fixer le prix et suivre la valeur des affaires",
	deal_stage_status_def:
		"Un statut d'étape d'affaire est l'étape d'une opportunité ou d'une affaire dans le pipeline de vente. Le statut de l'étape de l'affaire fournit des informations sur la progression de l'équipe de vente pour faire avancer l'opportunité vers une vente conclue. Les exemples comprennent 'nouveau', 'en discussion', 'gagné', 'perdu'",
	product_category_def:
		"Une catégorie de produit est un regroupement ou une classification de produits ou de services connexes. Les catégories de produits organisent et regroupent les produits ayant des caractéristiques, des fonctionnalités ou des marchés cibles similaires",
	product_type_def:
		"Un type de produit est un type ou une variante spécifique d'un produit au sein d'une catégorie de produits plus large. Les types de produits sont utilisés pour différencier davantage les produits individuels au sein d'une catégorie en fonction de caractéristiques ou de fonctionnalités spécifiques",
	task_stage_def:
		"Une étape de tâche est l'étape d'une tâche dans un flux de travail ou un processus plus large. Les étapes de tâches sont généralement utilisées pour suivre la progression et fournir une visibilité sur le statut d'une tâche particulière. Les exemples comprennent 'À faire', 'Planification', 'En cours', 'Terminée'",

	task_label_def:
		"Une étiquette de tâche est une balise ou un identifiant attribué à une tâche spécifique. Les étiquettes de tâche sont généralement utilisées pour catégoriser les tâches ou les activités en fonction de leur type, de leur priorité ou de leur statut",
	forms_template: "Créez des modèles et définissez des champs ici",
	accounts_template: "Créez des types ici",
	value: "Valeur",
	dtmf_option: "Option DTMF",
	all: "Tout",
	read: "Lu",
	assigned_to_me: "Assigné à moi",
	branch: "Branche",
	branches: "Branches",
	branch_details:
		"Une branche fait généralement référence à une unité ou une division distincte au sein de l'organisation chargée d'effectuer des fonctions ou des opérations spécifiques",
	add_branch: "Ajouter une branche",
	added_branch_successfully: "Branche ajoutée avec succès",
	error_adding_branch: "Impossible d'ajouter la branche",
	updated_branch_successfully: "Branche mise à jour avec succès",
	view_branch_access_denied:
		"Vous n'avez pas accès à la vue des branches de votre organisation. Veuillez contacter votre administrateur",
	error_updating_branch: "Impossible de mettre à jour la branche",
	edit_branch: "Modifier la branche",
	mentioned_you_in: "vous a mentionné dans",
	assigned_you_to: "vous a assigné à",
	task: "tâche",
	document: "document",
	call_agent: "{action} {agent}",
	revenue_by_deal_currency: "Revenu par devise de l'opportunité",
	belongs_to: "Appartient à :",
	me: "Moi",
	deals_without_stage: "Opportunités sans étape",
	enter_valid_number: "Veuillez entrer un numéro valide",
	next: "Suivant",
	previous: "Précédent",
	file_does_not_exist: "Oops ! Le fichier demandé n'existe pas",
	labels: "Étiquettes",
	auto_dialer: "Composeur automatique",
	add_auto_dialer: "Ajouter un composeur automatique",
	robot_call: "Appel de robot",
	add_robot_call: "Ajouter un appel de robot",
	add_campaign: "Ajouter une campagne",
	view_campaign: "Voir la campagne",
	campaign_stats: "Statistiques de la campagne",
	campaign_name: "Campagne : {campaign_name}",
	campaign_contacts: "Contacts de la campagne",
	view_campaign_access_denied:
		"Vous n'avez pas accès aux campagnes. Veuillez contacter votre administrateur",
	target: "Cible",
	target_type: "Type de cible",
	frequency: "Fréquence",
	once: "Une fois",
	daily: "Quotidien",
	weekly: "Hebdomadaire",
	monthly: "Mensuel",
	quarterly: "Trimestriel",
	bi_anually: "Bi-annuel",
	yearly: "Annuel",
	recipients: "Destinataires",
	campaigns: "Campagnes",
	contact_not_found: "Oops, contact introuvable",
	send_sms: "Envoyer un SMS",
	filters: "Filtres",
	start_date: "Date de début",
	end_date: "Date de fin",
	template: "Modèle",
	template_name: "Nom du modèle",
	save_template: "Enregistrer le modèle",
	discard: "Rejeter",
	webchat: "Chat Web",
	mobilechat: "Chat Mobile",
	api_key: "Clé API",
	copy_api_key: "Copier la clé API",
	webchat_def:
		"Un chat web est un outil de communication qui permet aux visiteurs de votre site web d'interagir avec vos agents ou de créer des tickets. Il se présente sous la forme d'une fenêtre contextuelle avec des capacités de chat qui apparaît sur le site web et permet aux visiteurs d'envoyer des messages et de recevoir des réponses des agents du support client.",
	to_use_this_webchat:
		"Pour utiliser le chat web sur votre site web, placez ce code dans la balise <body> à la racine de votre application, très probablement dans le fichier index.html, ou sur une page spécifique où vous avez besoin du chat web.",
	also_add_this_webchat:
		"Ensuite, copiez ce code et placez-le après la fermeture de la balise </body>. Le chat web devrait alors être prêt à fournir des capacités de chat en direct avec vos agents sur Chatdesk, ainsi que la possibilité pour les visiteurs de votre site web de créer des tickets.",
	connect_to_livechat: "Se connecter au chat en direct",
	disconnect_from_livechat: "Se déconnecter du chat en direct",
	sla: "SLA (Accord de niveau de service)",
	slas: "SLAs (Accords de niveau de service)",
	add_sla: "Ajouter un SLA",
	edit_sla: "Modifier un SLA",
	time_disclaimer: "Tous les délais sont en minutes",
	first_response_time_def:
		"Le temps de première réponse est le temps écoulé entre la création d'un ticket et le moment où l'agent fournit la première réponse ou marque le ticket comme 'En cours'.",
	first_response_time: "Temps de première réponse",
	handling_time: "Temps de traitement",
	handling_time_def:
		"Le temps de traitement est le temps entre la première réponse de l'agent ou le moment où le ticket est marqué comme 'En cours' et le moment où le ticket est marqué comme fermé.",
	resolution_time: "Temps de résolution",
	resolution_time_def:
		"Le temps de résolution est le temps total entre la création d'un ticket et sa clôture.",
	holiday: "Jour férié",
	holidays: "Jours fériés",
	add_holiday: "Ajouter un jour férié",
	edit_holiday: "Modifier un jour férié",
	business_hours: "Heures d'ouverture",
	no_business_hours_set: "Aucune heure d'ouverture n'a été définie",
	setup: "Configuration",
	verified: "Vérifié",
	copy_number: "Copier le numéro",
	all_numbers: "Tous les numéros",
	add_detail: "Ajouter {detail}",
	edit_detail: "Modifier {detail}",
	timeline: "Chronologie",
	ticket_count: "Nombre de tickets",
	escalation_agents: "Agents en charge des escalades pour cet SLA",
	first_response_time_breached: "Violation du temps de première réponse SLA",
	handling_time_breached: "Violation du temps de traitement SLA",
	resolution_time_breached: "Violation du temps de résolution SLA",
	ticket_categories_times: "Par catégories de tickets Scores/SLAs",
	data_by_categories: "Données par catégories",
	data_by_services: "Données par services",
	data_by_sources: "Données par sources",
	ticket_categories_statuses: "Statuts par catégories",
	ticket_categories_priorities: "Priorités par catégories",
	ticket_categories_sources: "Sources par catégories",
	ticket_services_statuses: "Statuts par services",
	ticket_services_priorities: "Priorités par services",
	ticket_services_sources: "Sources par services",
	ticket_sources_statuses: "Statuts par sources",
	ticket_sources_priorities: "Priorités par sources",
	chatdesk_analytics_access_denied:
		"Vous n'avez pas accès aux analyses de Chatdesk. Veuillez contacter votre administrateur.",
	manage_chatdesk_customizations_access_denied:
		"Vous n'avez pas accès à la gestion des personnalisations de Chatdesk. Veuillez contacter votre administrateur.",
	by_category: "Par catégorie de ticket",
	ticket_agents_times: "Métriques des agents",
	download_ticket: "Télécharger le ticket",
	subscriptions: "Abonnements",
	subscription_preferences: "Préférences",
	subscription_history: "Historique des abonnements",
	subscription_invoices: "Factures d'abonnement",
	subscription_invoices_description:
		"Consultez et gérez vos factures d'abonnement.",
	subscription_plans: "Plans d'abonnement",
	subscription_plans_description: "Passez à différents plans d'abonnement.",
	subscribed_plans: "Plans souscrits",
	subscribed_plans_description: "Consultez et gérez vos plans d'abonnement.",
	subscription_seats: "Places d'abonnement",
	wallets: "Portefeuilles",
	wallet_transactions: "Transactions de portefeuille",
	purchase: "Acheter",
	seats: "Places",
	wallets_description: "Consultez et gérez vos portefeuilles.",
	main_wallet: "Portefeuille principal",
	branch_wallet: "Portefeuille de branche",
	add_wallet: "Ajouter un portefeuille",
	edit_wallet: "Modifier un portefeuille",
	delete_wallet: "Supprimer un portefeuille",
	top_ups: "Rechargements",
	top_up_wallet: "Recharger le portefeuille",
	top_up_org_wallet: "Recharger le portefeuille de votre organisation",
	top_up_org_wallet_description:
		"Rechargez le portefeuille de votre organisation pour pouvoir acheter des fonctionnalités premium.",
	choose_payment_method: "Choisissez un mode de paiement",
	redeemed_units: "Unités échangées",
	redeemed_units_description: "Consultez et gérez vos unités échangées.",
	address_line_1: "Adresse (ligne 1)",
	address_line_2: "Adresse (ligne 2)",
	city: "Ville",
	state: "État",
	postal_code: "Code postal",
	country: "Pays",
	company_name: "Nom de l'entreprise",
	ticketing: "Gestion des tickets",
	avg_sentiment_score: "Score de sentiment moyen",
	avg_first_response_time: "Temps moyen de première réponse (min)",
	avg_handling_time: "Temps moyen de traitement (min)",
	avg_resolution_time: "Temps moyen de résolution (min)",
	avg_agent_csat_rating: "Note CSAT moyenne de l'agent",
	avg_company_csat_rating: "Note CSAT moyenne de l'entreprise",
	avg_nps_score: "Score NPS moyen",
	tickets_resolved: "Tickets marqués comme résolus par le client",
	tickets_unresolved: "Tickets marqués comme non résolus par le client",
	ticket_actions: "Actions disponibles pour les tickets",
	change_status: "Changer le statut",
	business_rules: "Règles métier",
	customizations: "Personnalisations",
	yes: "Oui",
	no: "Non",
	google_business_messaging: "Messagerie professionnelle Google",
	configurations_saved: "Configurations enregistrées avec succès",
	ticket_auto_assignment: "Attribution automatique des tickets",
	out_of_office_response: "Réponse automatique en dehors des heures de bureau",
	out_of_office_response_placeholder:
		"Saisissez votre réponse automatique en dehors des heures de bureau qui est automatiquement envoyée en dehors des heures de bureau",
	ticket_sharing_link: "Partager un lien de suivi de ticket avec les clients",
	ticket_sharing_link_sources: "Sources de liens de suivi de ticket",
	ticket_sharing_link_triggers: "Déclencheurs de liens de suivi de ticket",
	on_agent_assignment: "Lors de l'attribution à un agent",
	on_sla_breach: "En cas de non-respect de l'objectif SLA",
	on_ticket_closure: "Lors de la clôture du ticket",
	brand: "Marque",
	brand_display_name: "Nom d'affichage de la marque",
	brand_website: "Site web de la marque",
	agent_display_name: "Nom de l'agent tel qu'il apparaîtra aux utilisateurs",
	agent_logo_url:
		"URL du logo de l'agent, en tant qu'URL publiquement disponible",
	privacy_policy:
		"URL de la politique de confidentialité, commençant par 'https://'",
	agent_welcome_message:
		"Message de bienvenue de l'agent pour saluer les utilisateurs",
	place_ids:
		"IDs de lieu séparés par des virgules, par exemple, ChIJgUbEo8cfqokR5lP9_Wh_DaM, IhoSGAoUChIJ0U6OoscfqokR6P225pApu2UQDQ",
	place_id_disclaimer:
		"Ajoutez les IDs de lieu pour tous les emplacements de votre entreprise où vous avez besoin du bouton de chat. Trouvez vos IDs de lieu sur la carte à l'adresse : ",
	google_business_messaging_disclaimer:
		"Remarque : Une fois que vous avez ajouté avec succès ces détails, développez l'intégration dans le tableau et lancez la vérification de l'agent, puis une fois vérifié, lancez la vérification de l'emplacement. Une fois l'emplacement vérifié, vous devriez voir le bouton de chat sur Google.",
	verify: "Vérifier",
	launch_agent_verification: "Lancer la vérification de l'agent",
	launch_location_verification: "Lancer la vérification de l'emplacement",
	agent_verified: "Agent vérifié",
	location_verified: "Emplacement vérifié",
	contact_email: "E-mail de contact",
	none_select_text: "Aucun",
	call_to_action: "Appel à l'action",
	quick_reply: "Réponse rapide",
	marketing: "Marketing",
	utility: "Utilitaire",
	authentication: "Authentification",
	english: "Anglais",
	swahili: "Swahili",
	group: "Groupe",
	groups: "Groupes",
	select_keywords: "Sélectionner des mots-clés",
	select_group: "Sélectionner un groupe",
	csv: "CSV",
	json: "JSON",
	hours: "Heures",
	minutes: "Minutes",
	source_ticket: "Ticket de source",
	bot_builder: "Constructeur de bots",
	bot_flows: "Flux de bots",
	no_permission_to_access_ticket:
		"Vous n'avez pas la permission d'accéder à ce ticket",
	add_button_label: "Ajouter une étiquette de bouton",
	create_button_label: "Créer une étiquette de bouton",
	create_buttons_label: "Créer des étiquettes de bouton",
	call_phone: "Appeler le téléphone",
	visit_website: "Visiter le site web",
	queries: "Requêtes",
	query: "Requête",
	keywords: "Mots-clés",
	integrations_with_auto_create_leads:
		"Intégrations avec création automatique de prospects",
	auto_create_leads: "Créer automatiquement des prospects",
	webchat_prompt_message_placeholder:
		"Message de chat web sans salutation, par exemple, Bienvenue chez { Organization }. Nous sommes là pour vous aider",
	instagram_messenger: "Instagram Messenger",
	webchat_prompt_message: "Message de chat web sans salutation",
	ticket_closure_email_notification:
		"Notification par e-mail de clôture du ticket",
	ticket_assignment_email_notification:
		"Notification par e-mail d'attribution du ticket",
	edit_flow: "Modifier le flux",
	new_flow: "Nouveau flux",
	view_flow: "Voir le flux",
	building_blocks: "Blocs de construction",
	flow: "Flux",
	add_query: "Ajouter une requête",
	edit_query: "Modifier la requête",
	view_query: "Voir la requête",
	edit_group: "Modifier le groupe",
	calls: "Appels",
	chatdesk: "Chatdesk",
	nia: "Nia",
	billing: "Facturation",
	edit_form: "Modifier le formulaire ({ form_title })",
	unlink_catalog: "Dissocier le catalogue",
	closed_session: "Session fermée",
	active_session: "Session active",
	reset: "Réinitialiser",
	error_saving_flow: "Erreur lors de l'enregistrement du flux",
	flow_saved_successfully: "Flux enregistré avec succès",
	connect: "Connecter",
	connect_catalog: "Connecter le catalogue",
	catalog_connected: "Catalogue connecté",
	delete_flow: "Supprimer le flux",
	close_ticket: "Fermer le billet",
	confirm_close_ticket: "Veuillez confirmer la clôture de ce ticket",
	open_link: "Ouvrir le lien",
	total_calls_offered: "Toutes les offres des appels",
	total_calls_abandoned: "Toutes les offres des appels abonnées",
	total_calls_answered: "Toutes les appels répondus",
	wait_time: "Le temps d'attente",
	enter_number_country_code: "Entrez le numéro avec le code pays (ex: +254...)",
	job_title: "Titre d'emploi",
	job_department: "Département de l'emploi",
	nickname: "Surnom",
	other_name: "Autre nom",
	prefix: "Préfixe",
	suffix: "Suffixe",
	url: "URL",
	birthday: "Anniversaire",
	street: "Rue",
	address: "Adresse",
	others: "Autres",
	titles: "Titres",
	contact_disclaimer:
		"Nom et prénom obligatoires. Le numéro de téléphone doit commencer par l'indicatif du pays, par exemple +254...",
	directory_disclaimer: "Nom et extension obligatoires",
	personal_details: "Détails personnels",
	add_custom_field: "Ajouter un champ personnalisé",
	field_name: "Nom de domaine",
	contact_group: "Groupe de contacts",
	add_contacts_to_group: "Ajouter des contacts au groupe",
	edit_contact_group: "Modifier le groupe de contacts",
	creator: "Créateur",
	unknown: "Inconnu",
	contact_tags: "Balises de contact",
	contact_labels: "Étiquettes de contact",
	edit_label: "Modifier l'étiquette",
	autosend_csat_on_ticket_closure:
		"Envoi automatique du CSAT à la fermeture du ticket",
	send_csat: "Envoyer un CSAT",
	confirm_send_csat: "Veuillez confirmer l'envoi du CSAT",
	email_template: "Modèle d'e-mail",
	select_integration: "Sélectionnez Intégration",
	autoappend_agent_name: "Ajouter automatiquement une signature aux messages",
	ticket_assignment_email_notification_to_whole_group:
		"Notification par e-mail sur l'attribution de billets aux membres du groupe",
	ticket_closure_message_to_client:
		"Message CSAT de clôture du ticket au client",
	integrations_with_ticket_closure_message:
		"Intégrations avec le message CSAT de fermeture de ticket personnalisé",
	ticket_link_sharable_message_to_client:
		"Message de lien de suivi des billets au client",
	integrations_with_ticket_link_sharable:
		"Intégrations avec le message de lien de suivi des billets partageables",
	resolved_on: "Résolu le",
	first_time_marked_in_progress: "Marqué en cours",
	issue_resolved: "Problème résolu",
	agent_rating: "Évaluation des agents",
	company_rating: "Évaluation de l'entreprise",
	nps_score: "Score net du promoteur",
	use_full_agent_name_or_initials:
		"Utiliser le nom complet de l'agent ou les initiales pour les sources autres que le courrier électronique",
	full_name: "Nom et prénom",
	initials: "Initiales",
	email_signature: "Signature pour les messages électroniques",
	save_email_signature: "Enregistrer la signature électronique",
	email_signature_disclaimer:
		"Les espaces réservés valides pour la signature électronique sont: {{user.first_name}} {{user.last_name}} {{user.phone}} {{user.email}} {{user.email_signature}} {{company.name}}",
	insert_image: "Insérer une image",
	apply_filters: "Appliquer des filtres",
	on_ticket_creation: "Sur la création de tickets",
	serial_number: "Numéro de série",
	cc: "CC",
	reply_all: "Répondre à tous",
	auto_send_out_of_office_response:
		"Envoi automatique d'un message d'absence du bureau",
	integrations_with_out_of_office_response:
		"Intégration avec la réponse d'absence du bureau",
	forward: "Avant",
	ticket_serial_number_prefix: "Préfixe du numéro de série du ticket",
	call_timeout: "Délai d'appel",
	calls_per_session: "Appels par session",
	max_call_attempts: "Nombre maximal de tentatives d'appel",
	tracking_link_disclaimer:
		"Les espaces réservés valides sont : {{ticket.link}} {{ticket.id}} {{ticket.serial_number}}",
	ticket_sharing_page_fields:
		"Champs de ticket à afficher sur la page de suivi",
	id: "IDENTIFIANT",
};
export default fr;

const fre = {
	crm: "CRM",

	dashboard: "Tableau de bord",

	greeting: "Bonjour ",

	welcome_back: "Bienvenue !",

	stats: "Statistiques",

	accounts: "{title_de_compte}",

	tickets: "Tickets",

	analytics: "Analytique",

	csat: "CSAT",

	csat_surveys: "Enquêtes CSAT",

	surveys: "Enquêtes",

	survey: "Enquête",

	add_survey: "Ajouter une enquête",

	edit_survey: "Modifier une enquête",

	view_survey: "Afficher l'enquête",

	survey_added_successfully: "Enquête ajoutée avec succès",

	survey_updated_successfully: "Enquête modifiée avec succès",

	survey_deleted_successfully: "Enquête supprimée avec succès",

	questions: "Questions",

	question: "Question",

	add_question: "Ajouter une question",

	edit_question: "Modifier une question",

	view_question: "Afficher la question",

	question_added_successfully: "Question ajoutée avec succès",

	question_updated_successfully: "Question modifiée avec succès",

	question_rating: "Évaluation de la question",

	add_question_rating: "Ajouter une évaluation de question",

	add_csat: "Ajouter un CSAT",

	position: "Position",

	par_channels: "Par Sources",

	help_desk: "Service Client",

	admin: "Admin",

	crm_analytics: "Analyse de CRM",

	chatdesk_analytics: "Analyse de Chatdesk",

	chatdesk_overview: "Aperçu de Chatdesk",

	nia_overview: "Aperçu de Nia",

	add_mention: "Ajouter une Mention",

	edit_mention: "Modifier une Mention",

	add_keyword: "Ajouter un Mot-clé",

	edit_keyword: "Modifier un Mot-clé",

	hashtags: "Hashtags",

	mentions: "Mentions",

	mention: "Mention",

	keyword: "Mot-clé",

	overview: "Aperçu",

	cm_overview: "Aperçu du Module d'Appels",

	live_reports: "Rapports en Direct",

	agent_reports: "Rapports des Agents",

	voice_reports: "Rapports Vocaux",

	reports: "Rapports",

	nia_dashboard: "Tableau de bord Nia",

	add_hashtag: "Ajouter un Hashtag",

	edit_hashtag: "Modifier un Hashtag",

	crm_overview: "Aperçu de CRM",

	account_details: "Détails du Compte {account_title}",

	ticket_details: "Détails du Ticket",

	sentiments_overtime: "Émotions au fil du temps",

	words_frequency: "Fréquence des Mots",

	tweets_overtime: "Tweets au fil du temps",

	tweets: "Tweets",

	ticket: "Ticket",

	edit_ticket: "Modifier le Ticket",

	add_ticket: "Ajouter un Ticket",

	delete_ticket: "Supprimer le Ticket",

	no_tickets: "Pas de Tickets",

	compte: "Compte",

	leads: "Prospects",

	lead_source: "Source du Prospect",

	details: "Détails",

	ad: "Publicité",

	google: "Google",

	faqs: "FAQ",

	employee_referral: "Référence d'employé",

	lead_status: "Statut du Prospect",

	lead_type: "Type de Prospect",

	lead_types: "Types de Prospect",

	add_lead_type: "Ajouter un Type de Prospect",

	edit_lead_type: "Modifier un Type de Prospect",

	delete_lead_type: "Supprimer un Type de Prospect",

	lead_type_details: "Détails du Prospect",

	lead_type_templates_details: "Détails des Modèles de Prospect",

	lead_types_popover:
		"Créez des types de prospects pour catégoriser vos prospects et ajoutez des champs personnalisés à chaque type de prospect.",

	view_lead_type_access_denied:
		"Vous n'avez pas accès à la visualisation des Types de Prospect. Veuillez contacter votre administrateur.",

	view_lead_access_denied:
		"Vous n'avez pas accès à la visualisation des Prospects. Veuillez contacter votre administrateur.",

	working: "En cours",

	nurtured: "Nourri",

	macros: "Macros",

	actions: "Actions",

	converted: "Converti",

	all_contacts: "Tous les Contacts",

	contact_groups: "Groupes de Contacts",

	contacts: "Contacts",

	view_contact: "Voir le Contact",

	view_contact_access_denied:
		"Vous n'avez pas accès à la visualisation des Contacts. Veuillez contacter votre administrateur.",

	add_contact_access_denied:
		"Vous n'avez pas accès à l'ajout de Contacts. Veuillez contacter votre administrateur.",

	active: "Actif",

	inactive: "Inactif",

	search: "Rechercher",

	total: "Total",

	ticket_logs: "Logs de Ticket",

	new: "Nouveau",

	take_break: "Faire une pause",

	my_breaks: "Mes pauses",

	download: "Télécharger",

	download_leads_csv: "Télécharger les prospects en CSV",

	download_leads: "Télécharger les prospects",

	download_products_CSV: "Télécharger les produits en CSV",

	download_products: "Télécharger les produits",

	pick_up_where_you_left_off: "Reprendre où vous vous êtes arrêté",

	take_a_shortcut_to_tasks: "Gagner du temps pour les Tâches",

	add_a_task_from_here: "Ajouter une tâche ici",

	take_a_shortcut_to_adding_events:
		"Gagner du temps pour ajouter des événements",

	add_an_event_from_here: "Ajouter un événement ici",

	start_with_something_new: "Commencer par quelque chose de nouveau...",

	take_a_shortcut_to_deals_won_overtime:
		"Prenez un raccourci vers les offres gagnées dans le temps",

	check_analysis_from_here: "Vérifier l'analyse d'ici",

	check_analysis: "Vérifier l'analyse",

	get_started_with_bonga_crm: "Commencez avec Bonga CRM",

	create_a_new: "Créer un nouveau compte, contact, affaire ou prospect...",

	break: "Faire une pause",

	lead_contact: "Contact de prospect",

	select_reason: "Sélectionner un motif",

	lunch: "Déjeuner",

	tea: "Thé",

	health: "Santé",

	other: "Autre",

	team_chat: "Chat d'équipe",

	cancel: "Annuler",

	save: "Enregistrer",

	reset: "Réinitialiser",

	run: "Lancer",

	button: "Bouton",

	agents_online: "Agents en ligne",

	delete_social: "Supprimer les réseaux sociaux",

	monitor_calls: "Surveiller les appels",

	call_module: "Module d'appel",

	dialer: "Composeur",

	returning: "Retour",

	new_leads: "Nouveaux prospects",

	returning_leads: "Prospects de retour",

	new_contacts: "Nouveaux contacts",

	returning_contacts: "Contacts de retour",

	new_accounts: "Nouvelle {account_title}",

	returning_accounts: "{account_title} de retour",

	add_new_account: "Ajouter un nouveau {account_title}",

	add_new_lead: "Ajouter un nouveau prospect",

	add_new_contact: "Ajouter un nouveau contact",

	add_new_document: "Ajouter un nouveau document",

	add_new_task: "Ajouter une nouvelle tâche",

	add_new_task_stage: "Ajouter une nouvelle étape de tâche",

	add_new_product: "Ajouter un nouveau produit",

	add_new_deal: "Ajouter une nouvelle affaire",

	edit_deal: "Modifier l'affaire",

	sort_by: "Trier par",

	edit_task: "Modifier la tâche",

	filter_by: "Filtrer par",

	delete_account: "Supprimer {account_title}",

	phone_feature_under_development:
		"Fonctionnalité téléphonique en cours de développement",

	on_development_description:
		"Cette fonctionnalité est en cours de développement. Veuillez vérifier ultérieurement.",

	chat_feature_under_development:
		"Fonctionnalité de chat en cours de développement",

	my_dashboard: "Mon tableau de bord",

	deals_won_overTime: "Affaires gagnées au fil du temps",

	inbound_outbound: "Entrant / Sortant",

	welcome_to_crm: "Bienvenue sur CRM",

	upgrade_crm_to_access_premium_statistics:
		"Mettez à niveau CRM pour accéder aux statistiques premium",

	products: "Produits",

	product: "Produit",

	remarks: "Remarques",

	users: "Utilisateurs",

	notifications: "Notifications",

	no_notifications: "Pas de notifications",

	no_notifications_description:
		"Vous n'avez pas encore de notifications, toutes vos nouvelles notifications apparaîtront ici",

	no_read_notifications_description:
		"Vous n'avez pas de notifications dans cette catégorie",

	add_product: "Ajouter un produit",

	add_lead_status: "Ajouter le statut du prospect",

	edit_lead: "Modifier le prospect",

	delete_lead: "Supprimer le prospect",

	add_alt_number: "Ajouter un autre numéro",

	edit_contact: "Modifier le contact",

	delete_contact: "Supprimer le contact",

	edit_account: "Modifier le compte",

	view_account_access_denied:
		"Vous n'avez pas accès à la visualisation des comptes. Veuillez contacter votre administrateur",

	delete_product: "Supprimer le produit",

	edit_product: "Modifier le produit",

	view_product_access_denied:
		"Vous n'avez pas accès à la vue des produits. Veuillez contacter votre administrateur.",

	edit_lead_status: "Modifier l'état du lead",

	lead_updated_successfully: "Lead mis à jour avec succès",

	add_lead_rating_label: "Ajouter une étiquette d'évaluation du lead",

	edit_lead_rating_label: "Modifier l'étiquette d'évaluation du lead",

	add_deal_currency: "Ajouter une devise à la transaction",

	edit_deal_currency: "Modifier la devise de la transaction",

	add_deal_stage_status: "Ajouter un statut de phase de la transaction",

	edit_deal_stage_status: "Modifier un statut de phase de transaction",

	login: "Se connecter",

	reset_password: "Réinitialiser le mot de passe",

	enter_email: "Veuillez saisir l'email de votre compte",

	reset_password_description:
		"Entrez votre email pour réinitialiser votre mot de passe",

	check_email: "Vérifiez votre email",

	check_email_description:
		"Un email avec un mot de passe temporaire vous sera envoyé. Veuillez réinitialiser votre mot de passe après vous être connecté.",

	failed_to_send_email: "Échec de l'envoi de l'email",

	add_account: "Ajouter {account_title} (facultatif)",

	event_account_description:
		"Sélectionnez un {account_title} à associer à cet événement.",

	add_guests_from_contacts:
		"Inviter des invités depuis vos contacts et collègues à assister à cet événement.",

	deals: "Transactions",

	types: "Types",

	profile: "Profil",

	settings: "Paramètres",

	personal_settings: "Paramètres personnels",

	profile_settings: "Paramètres du profil",

	manage_password: "Gérer le mot de passe",

	manage_calls: "Gérer les appels",

	manage_crm: "Gérer le CRM",

	manage_chatdesk: "Gérer le Chatdesk",

	manage_profile: "Gérer le profil",

	manage_business_rules: "Gérer les règles d'entreprise",

	manage_org_details: "Gérer les détails de l'organisation",

	manage_branches: "Gérer les succursales",

	manage_users: "Gérer les utilisateurs",

	manage_roles: "Gérer les rôles",

	manage_role: "Gérer le rôle",

	manage: "Gérer {item}",

	feature: "Fonctionnalité",

	view: "Vue",

	change: "Changer",

	create: "Créer",

	roles: "Rôles",

	assign_user_role: "Attribuer un rôle à l'utilisateur",

	user_roles_assigned_successfully: "Rôles d'utilisateur attribués avec succès",

	user_roles: "Rôles d'utilisateur",

	permissions: "Permissions",

	click_to_edit: "Cliquez pour modifier la date et l'heure",

	time: "Heure",

	week: "Semaine",

	calendar: "Calendrier",

	contact: "Contact",

	next_week: "Semaine prochaine",

	previous_week: "Semaine précédente",

	event: "Événement",

	day: "Jour",

	add_event: "Ajouter un événement",

	edit_event: "Modifier un événement",

	add_event_description: "Ajouter une description d'événement",

	add_event_title: "Ajouter un titre d'événement",

	add_guests: "Ajouter des invités",

	end_of_scroll: "Pas plus de résultats... 📃",

	start_of_scroll: "Recherche des billets... 🔍",

	summary: "Sommaire",

	reply_with_macro: "Répondre avec une macro",

	reply_with: "Répondre avec",

	add_category: "Ajouter une catégorie",

	view_category: "Afficher la catégorie",

	edit_category: "Modifier la catégorie",

	delete_category: "Supprimer la catégorie",

	manage_categories_access_denied:
		"Vous n'avez pas accès à la gestion des catégories",

	status: "Statut",

	statuses: "Statuts",

	role: "Rôle",

	add_role: "Ajouter un rôle",

	edit_role: "Modifier un rôle",

	view_role: "Voir un rôle",

	delete_role: "Supprimer un rôle",

	role_added_successfully: "Rôle ajouté avec succès",

	updated_successfully: "Mise à jour effectuée avec succès",

	add_status: "Ajouter un statut",

	view_status: "Voir un statut",

	edit_status: "Modifier un statut",

	delete_status: "Supprimer un statut",

	add_to_list: "Ajouter à la liste",

	edit_date: "Modifier la date",

	channels: "Canaux",

	add_channel: "Ajouter un canal",

	view_channel: "Voir un canal",

	edit_channel: "Modifier un canal",

	delete_channel: "Supprimer un canal",

	upload_file: "Téléverser un fichier",

	upload_description:
		"Pour de meilleurs résultats, veuillez téléverser un fichier WAV mono 16 bits à 8 ou 16 khz.",

	date_created: "Date de création",

	click_or_drag_file_to_this_area_to_upload:
		"Cliquez ou faites glisser le fichier dans cette zone pour téléverser",

	click_to_add_file: "Cliquez pour ajouter un fichier",

	priorities: "Priorités",

	add_priority: "Ajouter une priorité",

	view_priority: "Voir une priorité",

	edit_priority: "Modifier une priorité",

	delete_priority: "Supprimer une priorité",

	services: "Services",

	add_service: "Ajouter un service",

	view_service: "Voir un service",

	edit_service: "Modifier un service",

	delete_service: "Supprimer un service",

	clients: "Clients",

	add_client: "Ajouter un client",

	view_client: "Voir un client",

	edit_client: "Modifier un client",

	add_socials: "Ajouter des réseaux sociaux",

	socials: "Réseaux sociaux",

	add_social: "Ajouter un réseau social",

	social_handle: "Identifiant de réseau social",

	handle: "Identifiant",

	display_name: "Nom d'affichage",

	delete_client: "Supprimer un client",

	agents: "Agents",

	platform: "Plateforme",

	to: "À",

	adding_social: "Ajout d'un identifiant de réseau social",

	from: "De",

	sending: "Envoi en cours...",

	email_placeholder: "Écrivez votre email ici",

	add_label: "Ajouter une étiquette",

	add_new_label: "Ajouter une nouvelle étiquette",

	add_agent: "Ajouter un agent",

	view_agent: "Voir un agent",

	edit_agent: "Modifier un agent",

	delete_agent: "Supprimer un agent",

	view_macros: "Voir les macros",

	edit_macros: "Modifier les macros",

	delete_macros: "Supprimer les macros",

	delete_confirm: "Êtes-vous sûr de vouloir supprimer cet élément ",

	delete_success: "Élément supprimé avec succès",

	delete_error: "Erreur lors de la suppression de l'élément",

	assign_agent: "Attribuer un agent",

	assign_agent_role: "Attribuer un rôle à un agent",

	add_macros: "Ajouter des macros",

	add_macro: "Ajouter une macro",

	view_macro: "Voir une macro",

	edit_macro: "Modifier une macro",

	delete_macro: "Supprimer une macro",

	message_template: "Modèle de message",

	add_message_template: "Ajouter un modèle",

	edit_message_template: "Modifier un modèle",

	new_template_body_placeholder: "Le corps du message ici",

	new_template_header_placeholder: "Le texte d'en-tête ici",

	new_template_footer_placeholder: "Le texte de pied de page ici",

	apps: "Applications",

	send_email: "Envoyer un email",

	inbox: "Boîte de réception",

	sent: "Envoyé",

	close: "Fermer",

	sync_with_google: "Synchroniser avec Google",

	use_without_google: "Utiliser sans Google",

	account_not_synced: "Compte non synchronisé",

	account_not_synced_description:
		"Votre compte n'est pas synchronisé avec Google. Veuillez le synchroniser pour accéder à vos emails, événements et contacts.",

	gmail_auth_response: "Le jeton n'existe pas, veuillez vous authentifier",

	drafts: "Brouillons",

	trash: "Corbeille",

	compose: "Composer",

	update: "Mettre à jour",

	not_found: "Cette page n'existe pas ! Veuillez vérifier l'URL et réessayer.",

	page_moved: "Page déplacée",

	page_moved_description:
		"Cette page a été déplacée vers un nouvel emplacement. Veuillez cliquer sur le bouton ci-dessous pour accéder à la nouvelle page.",

	go_to_new_page: "Aller à la nouvelle page",

	latest: "Dernier",

	back: "Retour",

	back_home: "Retour à la page d'accueil",

	update_success: "Mise à jour effectuée avec succès",

	update_error: "Erreur lors de la mise à jour",

	template_created_successfully: "Modèle créé avec succès",

	open_link: "Open Link",

	enter_number_country_code: "Entrez le numéro avec le code pays (ex: +254...)",

	call_timeout: "Temps d'appel",
};
