/**
 * @fileoverview gRPC-Web generated client stub for da.proto
 * @enhanceable
 * @public
 */

// Code generated by protoc-gen-grpc-web. DO NOT EDIT.
// versions:
// 	protoc-gen-grpc-web v1.5.0
// 	protoc              v4.25.2
// source: commerce.proto


/* eslint-disable */
// @ts-nocheck



const grpc = {};
grpc.web = require('grpc-web');

const proto = {};
proto.da = {};
proto.da.proto = require('./commerce_pb.js');

/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.da.proto.OrderStatusServiceClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.da.proto.OrderStatusServicePromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.da.proto.ListOrderStatusRequest,
 *   !proto.da.proto.ListOrderStatusResponse>}
 */
const methodDescriptor_OrderStatusService_ListOrderStatus = new grpc.web.MethodDescriptor(
  '/da.proto.OrderStatusService/ListOrderStatus',
  grpc.web.MethodType.UNARY,
  proto.da.proto.ListOrderStatusRequest,
  proto.da.proto.ListOrderStatusResponse,
  /**
   * @param {!proto.da.proto.ListOrderStatusRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.da.proto.ListOrderStatusResponse.deserializeBinary
);


/**
 * @param {!proto.da.proto.ListOrderStatusRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.da.proto.ListOrderStatusResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.da.proto.ListOrderStatusResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.da.proto.OrderStatusServiceClient.prototype.listOrderStatus =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/da.proto.OrderStatusService/ListOrderStatus',
      request,
      metadata || {},
      methodDescriptor_OrderStatusService_ListOrderStatus,
      callback);
};


/**
 * @param {!proto.da.proto.ListOrderStatusRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.da.proto.ListOrderStatusResponse>}
 *     Promise that resolves to the response
 */
proto.da.proto.OrderStatusServicePromiseClient.prototype.listOrderStatus =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/da.proto.OrderStatusService/ListOrderStatus',
      request,
      metadata || {},
      methodDescriptor_OrderStatusService_ListOrderStatus);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.da.proto.CreateOrderStatusRequest,
 *   !proto.da.proto.CreateOrderStatusResponse>}
 */
const methodDescriptor_OrderStatusService_CreateOrderStatus = new grpc.web.MethodDescriptor(
  '/da.proto.OrderStatusService/CreateOrderStatus',
  grpc.web.MethodType.UNARY,
  proto.da.proto.CreateOrderStatusRequest,
  proto.da.proto.CreateOrderStatusResponse,
  /**
   * @param {!proto.da.proto.CreateOrderStatusRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.da.proto.CreateOrderStatusResponse.deserializeBinary
);


/**
 * @param {!proto.da.proto.CreateOrderStatusRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.da.proto.CreateOrderStatusResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.da.proto.CreateOrderStatusResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.da.proto.OrderStatusServiceClient.prototype.createOrderStatus =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/da.proto.OrderStatusService/CreateOrderStatus',
      request,
      metadata || {},
      methodDescriptor_OrderStatusService_CreateOrderStatus,
      callback);
};


/**
 * @param {!proto.da.proto.CreateOrderStatusRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.da.proto.CreateOrderStatusResponse>}
 *     Promise that resolves to the response
 */
proto.da.proto.OrderStatusServicePromiseClient.prototype.createOrderStatus =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/da.proto.OrderStatusService/CreateOrderStatus',
      request,
      metadata || {},
      methodDescriptor_OrderStatusService_CreateOrderStatus);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.da.proto.ReadOrderStatusRequest,
 *   !proto.da.proto.ReadOrderStatusResponse>}
 */
const methodDescriptor_OrderStatusService_ReadOrderStatus = new grpc.web.MethodDescriptor(
  '/da.proto.OrderStatusService/ReadOrderStatus',
  grpc.web.MethodType.UNARY,
  proto.da.proto.ReadOrderStatusRequest,
  proto.da.proto.ReadOrderStatusResponse,
  /**
   * @param {!proto.da.proto.ReadOrderStatusRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.da.proto.ReadOrderStatusResponse.deserializeBinary
);


/**
 * @param {!proto.da.proto.ReadOrderStatusRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.da.proto.ReadOrderStatusResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.da.proto.ReadOrderStatusResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.da.proto.OrderStatusServiceClient.prototype.readOrderStatus =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/da.proto.OrderStatusService/ReadOrderStatus',
      request,
      metadata || {},
      methodDescriptor_OrderStatusService_ReadOrderStatus,
      callback);
};


/**
 * @param {!proto.da.proto.ReadOrderStatusRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.da.proto.ReadOrderStatusResponse>}
 *     Promise that resolves to the response
 */
proto.da.proto.OrderStatusServicePromiseClient.prototype.readOrderStatus =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/da.proto.OrderStatusService/ReadOrderStatus',
      request,
      metadata || {},
      methodDescriptor_OrderStatusService_ReadOrderStatus);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.da.proto.UpdateOrderStatusRequest,
 *   !proto.da.proto.UpdateOrderStatusResponse>}
 */
const methodDescriptor_OrderStatusService_UpdateOrderStatus = new grpc.web.MethodDescriptor(
  '/da.proto.OrderStatusService/UpdateOrderStatus',
  grpc.web.MethodType.UNARY,
  proto.da.proto.UpdateOrderStatusRequest,
  proto.da.proto.UpdateOrderStatusResponse,
  /**
   * @param {!proto.da.proto.UpdateOrderStatusRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.da.proto.UpdateOrderStatusResponse.deserializeBinary
);


/**
 * @param {!proto.da.proto.UpdateOrderStatusRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.da.proto.UpdateOrderStatusResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.da.proto.UpdateOrderStatusResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.da.proto.OrderStatusServiceClient.prototype.updateOrderStatus =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/da.proto.OrderStatusService/UpdateOrderStatus',
      request,
      metadata || {},
      methodDescriptor_OrderStatusService_UpdateOrderStatus,
      callback);
};


/**
 * @param {!proto.da.proto.UpdateOrderStatusRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.da.proto.UpdateOrderStatusResponse>}
 *     Promise that resolves to the response
 */
proto.da.proto.OrderStatusServicePromiseClient.prototype.updateOrderStatus =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/da.proto.OrderStatusService/UpdateOrderStatus',
      request,
      metadata || {},
      methodDescriptor_OrderStatusService_UpdateOrderStatus);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.da.proto.DeleteOrderStatusRequest,
 *   !proto.da.proto.DeleteOrderStatusResponse>}
 */
const methodDescriptor_OrderStatusService_DeleteOrderStatus = new grpc.web.MethodDescriptor(
  '/da.proto.OrderStatusService/DeleteOrderStatus',
  grpc.web.MethodType.UNARY,
  proto.da.proto.DeleteOrderStatusRequest,
  proto.da.proto.DeleteOrderStatusResponse,
  /**
   * @param {!proto.da.proto.DeleteOrderStatusRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.da.proto.DeleteOrderStatusResponse.deserializeBinary
);


/**
 * @param {!proto.da.proto.DeleteOrderStatusRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.da.proto.DeleteOrderStatusResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.da.proto.DeleteOrderStatusResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.da.proto.OrderStatusServiceClient.prototype.deleteOrderStatus =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/da.proto.OrderStatusService/DeleteOrderStatus',
      request,
      metadata || {},
      methodDescriptor_OrderStatusService_DeleteOrderStatus,
      callback);
};


/**
 * @param {!proto.da.proto.DeleteOrderStatusRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.da.proto.DeleteOrderStatusResponse>}
 *     Promise that resolves to the response
 */
proto.da.proto.OrderStatusServicePromiseClient.prototype.deleteOrderStatus =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/da.proto.OrderStatusService/DeleteOrderStatus',
      request,
      metadata || {},
      methodDescriptor_OrderStatusService_DeleteOrderStatus);
};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.da.proto.ShopServiceClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.da.proto.ShopServicePromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.da.proto.CreateShopRequest,
 *   !proto.da.proto.CreateShopResponse>}
 */
const methodDescriptor_ShopService_CreateShop = new grpc.web.MethodDescriptor(
  '/da.proto.ShopService/CreateShop',
  grpc.web.MethodType.UNARY,
  proto.da.proto.CreateShopRequest,
  proto.da.proto.CreateShopResponse,
  /**
   * @param {!proto.da.proto.CreateShopRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.da.proto.CreateShopResponse.deserializeBinary
);


/**
 * @param {!proto.da.proto.CreateShopRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.da.proto.CreateShopResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.da.proto.CreateShopResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.da.proto.ShopServiceClient.prototype.createShop =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/da.proto.ShopService/CreateShop',
      request,
      metadata || {},
      methodDescriptor_ShopService_CreateShop,
      callback);
};


/**
 * @param {!proto.da.proto.CreateShopRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.da.proto.CreateShopResponse>}
 *     Promise that resolves to the response
 */
proto.da.proto.ShopServicePromiseClient.prototype.createShop =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/da.proto.ShopService/CreateShop',
      request,
      metadata || {},
      methodDescriptor_ShopService_CreateShop);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.da.proto.ReadShopRequest,
 *   !proto.da.proto.ReadShopResponse>}
 */
const methodDescriptor_ShopService_ReadShop = new grpc.web.MethodDescriptor(
  '/da.proto.ShopService/ReadShop',
  grpc.web.MethodType.UNARY,
  proto.da.proto.ReadShopRequest,
  proto.da.proto.ReadShopResponse,
  /**
   * @param {!proto.da.proto.ReadShopRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.da.proto.ReadShopResponse.deserializeBinary
);


/**
 * @param {!proto.da.proto.ReadShopRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.da.proto.ReadShopResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.da.proto.ReadShopResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.da.proto.ShopServiceClient.prototype.readShop =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/da.proto.ShopService/ReadShop',
      request,
      metadata || {},
      methodDescriptor_ShopService_ReadShop,
      callback);
};


/**
 * @param {!proto.da.proto.ReadShopRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.da.proto.ReadShopResponse>}
 *     Promise that resolves to the response
 */
proto.da.proto.ShopServicePromiseClient.prototype.readShop =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/da.proto.ShopService/ReadShop',
      request,
      metadata || {},
      methodDescriptor_ShopService_ReadShop);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.da.proto.UpdateShopRequest,
 *   !proto.da.proto.UpdateShopResponse>}
 */
const methodDescriptor_ShopService_UpdateShop = new grpc.web.MethodDescriptor(
  '/da.proto.ShopService/UpdateShop',
  grpc.web.MethodType.UNARY,
  proto.da.proto.UpdateShopRequest,
  proto.da.proto.UpdateShopResponse,
  /**
   * @param {!proto.da.proto.UpdateShopRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.da.proto.UpdateShopResponse.deserializeBinary
);


/**
 * @param {!proto.da.proto.UpdateShopRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.da.proto.UpdateShopResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.da.proto.UpdateShopResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.da.proto.ShopServiceClient.prototype.updateShop =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/da.proto.ShopService/UpdateShop',
      request,
      metadata || {},
      methodDescriptor_ShopService_UpdateShop,
      callback);
};


/**
 * @param {!proto.da.proto.UpdateShopRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.da.proto.UpdateShopResponse>}
 *     Promise that resolves to the response
 */
proto.da.proto.ShopServicePromiseClient.prototype.updateShop =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/da.proto.ShopService/UpdateShop',
      request,
      metadata || {},
      methodDescriptor_ShopService_UpdateShop);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.da.proto.DeleteShopRequest,
 *   !proto.da.proto.DeleteShopResponse>}
 */
const methodDescriptor_ShopService_DeleteShop = new grpc.web.MethodDescriptor(
  '/da.proto.ShopService/DeleteShop',
  grpc.web.MethodType.UNARY,
  proto.da.proto.DeleteShopRequest,
  proto.da.proto.DeleteShopResponse,
  /**
   * @param {!proto.da.proto.DeleteShopRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.da.proto.DeleteShopResponse.deserializeBinary
);


/**
 * @param {!proto.da.proto.DeleteShopRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.da.proto.DeleteShopResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.da.proto.DeleteShopResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.da.proto.ShopServiceClient.prototype.deleteShop =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/da.proto.ShopService/DeleteShop',
      request,
      metadata || {},
      methodDescriptor_ShopService_DeleteShop,
      callback);
};


/**
 * @param {!proto.da.proto.DeleteShopRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.da.proto.DeleteShopResponse>}
 *     Promise that resolves to the response
 */
proto.da.proto.ShopServicePromiseClient.prototype.deleteShop =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/da.proto.ShopService/DeleteShop',
      request,
      metadata || {},
      methodDescriptor_ShopService_DeleteShop);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.da.proto.ListShopRequest,
 *   !proto.da.proto.ListShopResponse>}
 */
const methodDescriptor_ShopService_ListShop = new grpc.web.MethodDescriptor(
  '/da.proto.ShopService/ListShop',
  grpc.web.MethodType.UNARY,
  proto.da.proto.ListShopRequest,
  proto.da.proto.ListShopResponse,
  /**
   * @param {!proto.da.proto.ListShopRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.da.proto.ListShopResponse.deserializeBinary
);


/**
 * @param {!proto.da.proto.ListShopRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.da.proto.ListShopResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.da.proto.ListShopResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.da.proto.ShopServiceClient.prototype.listShop =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/da.proto.ShopService/ListShop',
      request,
      metadata || {},
      methodDescriptor_ShopService_ListShop,
      callback);
};


/**
 * @param {!proto.da.proto.ListShopRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.da.proto.ListShopResponse>}
 *     Promise that resolves to the response
 */
proto.da.proto.ShopServicePromiseClient.prototype.listShop =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/da.proto.ShopService/ListShop',
      request,
      metadata || {},
      methodDescriptor_ShopService_ListShop);
};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.da.proto.ProductServiceClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.da.proto.ProductServicePromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.da.proto.CreateProductRequest,
 *   !proto.da.proto.CreateProductResponse>}
 */
const methodDescriptor_ProductService_CreateProduct = new grpc.web.MethodDescriptor(
  '/da.proto.ProductService/CreateProduct',
  grpc.web.MethodType.UNARY,
  proto.da.proto.CreateProductRequest,
  proto.da.proto.CreateProductResponse,
  /**
   * @param {!proto.da.proto.CreateProductRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.da.proto.CreateProductResponse.deserializeBinary
);


/**
 * @param {!proto.da.proto.CreateProductRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.da.proto.CreateProductResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.da.proto.CreateProductResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.da.proto.ProductServiceClient.prototype.createProduct =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/da.proto.ProductService/CreateProduct',
      request,
      metadata || {},
      methodDescriptor_ProductService_CreateProduct,
      callback);
};


/**
 * @param {!proto.da.proto.CreateProductRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.da.proto.CreateProductResponse>}
 *     Promise that resolves to the response
 */
proto.da.proto.ProductServicePromiseClient.prototype.createProduct =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/da.proto.ProductService/CreateProduct',
      request,
      metadata || {},
      methodDescriptor_ProductService_CreateProduct);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.da.proto.ReadProductRequest,
 *   !proto.da.proto.ReadProductResponse>}
 */
const methodDescriptor_ProductService_ReadProduct = new grpc.web.MethodDescriptor(
  '/da.proto.ProductService/ReadProduct',
  grpc.web.MethodType.UNARY,
  proto.da.proto.ReadProductRequest,
  proto.da.proto.ReadProductResponse,
  /**
   * @param {!proto.da.proto.ReadProductRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.da.proto.ReadProductResponse.deserializeBinary
);


/**
 * @param {!proto.da.proto.ReadProductRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.da.proto.ReadProductResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.da.proto.ReadProductResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.da.proto.ProductServiceClient.prototype.readProduct =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/da.proto.ProductService/ReadProduct',
      request,
      metadata || {},
      methodDescriptor_ProductService_ReadProduct,
      callback);
};


/**
 * @param {!proto.da.proto.ReadProductRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.da.proto.ReadProductResponse>}
 *     Promise that resolves to the response
 */
proto.da.proto.ProductServicePromiseClient.prototype.readProduct =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/da.proto.ProductService/ReadProduct',
      request,
      metadata || {},
      methodDescriptor_ProductService_ReadProduct);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.da.proto.ListProductsRequest,
 *   !proto.da.proto.ListProductsResponse>}
 */
const methodDescriptor_ProductService_ListProducts = new grpc.web.MethodDescriptor(
  '/da.proto.ProductService/ListProducts',
  grpc.web.MethodType.UNARY,
  proto.da.proto.ListProductsRequest,
  proto.da.proto.ListProductsResponse,
  /**
   * @param {!proto.da.proto.ListProductsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.da.proto.ListProductsResponse.deserializeBinary
);


/**
 * @param {!proto.da.proto.ListProductsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.da.proto.ListProductsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.da.proto.ListProductsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.da.proto.ProductServiceClient.prototype.listProducts =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/da.proto.ProductService/ListProducts',
      request,
      metadata || {},
      methodDescriptor_ProductService_ListProducts,
      callback);
};


/**
 * @param {!proto.da.proto.ListProductsRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.da.proto.ListProductsResponse>}
 *     Promise that resolves to the response
 */
proto.da.proto.ProductServicePromiseClient.prototype.listProducts =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/da.proto.ProductService/ListProducts',
      request,
      metadata || {},
      methodDescriptor_ProductService_ListProducts);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.da.proto.UpdateProductRequest,
 *   !proto.da.proto.UpdateProductResponse>}
 */
const methodDescriptor_ProductService_UpdateProduct = new grpc.web.MethodDescriptor(
  '/da.proto.ProductService/UpdateProduct',
  grpc.web.MethodType.UNARY,
  proto.da.proto.UpdateProductRequest,
  proto.da.proto.UpdateProductResponse,
  /**
   * @param {!proto.da.proto.UpdateProductRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.da.proto.UpdateProductResponse.deserializeBinary
);


/**
 * @param {!proto.da.proto.UpdateProductRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.da.proto.UpdateProductResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.da.proto.UpdateProductResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.da.proto.ProductServiceClient.prototype.updateProduct =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/da.proto.ProductService/UpdateProduct',
      request,
      metadata || {},
      methodDescriptor_ProductService_UpdateProduct,
      callback);
};


/**
 * @param {!proto.da.proto.UpdateProductRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.da.proto.UpdateProductResponse>}
 *     Promise that resolves to the response
 */
proto.da.proto.ProductServicePromiseClient.prototype.updateProduct =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/da.proto.ProductService/UpdateProduct',
      request,
      metadata || {},
      methodDescriptor_ProductService_UpdateProduct);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.da.proto.DeleteProductRequest,
 *   !proto.da.proto.DeleteProductResponse>}
 */
const methodDescriptor_ProductService_DeleteProduct = new grpc.web.MethodDescriptor(
  '/da.proto.ProductService/DeleteProduct',
  grpc.web.MethodType.UNARY,
  proto.da.proto.DeleteProductRequest,
  proto.da.proto.DeleteProductResponse,
  /**
   * @param {!proto.da.proto.DeleteProductRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.da.proto.DeleteProductResponse.deserializeBinary
);


/**
 * @param {!proto.da.proto.DeleteProductRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.da.proto.DeleteProductResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.da.proto.DeleteProductResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.da.proto.ProductServiceClient.prototype.deleteProduct =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/da.proto.ProductService/DeleteProduct',
      request,
      metadata || {},
      methodDescriptor_ProductService_DeleteProduct,
      callback);
};


/**
 * @param {!proto.da.proto.DeleteProductRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.da.proto.DeleteProductResponse>}
 *     Promise that resolves to the response
 */
proto.da.proto.ProductServicePromiseClient.prototype.deleteProduct =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/da.proto.ProductService/DeleteProduct',
      request,
      metadata || {},
      methodDescriptor_ProductService_DeleteProduct);
};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.da.proto.OrderServiceClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.da.proto.OrderServicePromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.da.proto.CreateOrderRequest,
 *   !proto.da.proto.CreateOrderResponse>}
 */
const methodDescriptor_OrderService_CreateOrder = new grpc.web.MethodDescriptor(
  '/da.proto.OrderService/CreateOrder',
  grpc.web.MethodType.UNARY,
  proto.da.proto.CreateOrderRequest,
  proto.da.proto.CreateOrderResponse,
  /**
   * @param {!proto.da.proto.CreateOrderRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.da.proto.CreateOrderResponse.deserializeBinary
);


/**
 * @param {!proto.da.proto.CreateOrderRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.da.proto.CreateOrderResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.da.proto.CreateOrderResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.da.proto.OrderServiceClient.prototype.createOrder =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/da.proto.OrderService/CreateOrder',
      request,
      metadata || {},
      methodDescriptor_OrderService_CreateOrder,
      callback);
};


/**
 * @param {!proto.da.proto.CreateOrderRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.da.proto.CreateOrderResponse>}
 *     Promise that resolves to the response
 */
proto.da.proto.OrderServicePromiseClient.prototype.createOrder =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/da.proto.OrderService/CreateOrder',
      request,
      metadata || {},
      methodDescriptor_OrderService_CreateOrder);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.da.proto.ReadOrderRequest,
 *   !proto.da.proto.ReadOrderResponse>}
 */
const methodDescriptor_OrderService_ReadOrder = new grpc.web.MethodDescriptor(
  '/da.proto.OrderService/ReadOrder',
  grpc.web.MethodType.UNARY,
  proto.da.proto.ReadOrderRequest,
  proto.da.proto.ReadOrderResponse,
  /**
   * @param {!proto.da.proto.ReadOrderRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.da.proto.ReadOrderResponse.deserializeBinary
);


/**
 * @param {!proto.da.proto.ReadOrderRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.da.proto.ReadOrderResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.da.proto.ReadOrderResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.da.proto.OrderServiceClient.prototype.readOrder =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/da.proto.OrderService/ReadOrder',
      request,
      metadata || {},
      methodDescriptor_OrderService_ReadOrder,
      callback);
};


/**
 * @param {!proto.da.proto.ReadOrderRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.da.proto.ReadOrderResponse>}
 *     Promise that resolves to the response
 */
proto.da.proto.OrderServicePromiseClient.prototype.readOrder =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/da.proto.OrderService/ReadOrder',
      request,
      metadata || {},
      methodDescriptor_OrderService_ReadOrder);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.da.proto.ListOrdersRequest,
 *   !proto.da.proto.ListOrdersResponse>}
 */
const methodDescriptor_OrderService_ListOrders = new grpc.web.MethodDescriptor(
  '/da.proto.OrderService/ListOrders',
  grpc.web.MethodType.UNARY,
  proto.da.proto.ListOrdersRequest,
  proto.da.proto.ListOrdersResponse,
  /**
   * @param {!proto.da.proto.ListOrdersRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.da.proto.ListOrdersResponse.deserializeBinary
);


/**
 * @param {!proto.da.proto.ListOrdersRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.da.proto.ListOrdersResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.da.proto.ListOrdersResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.da.proto.OrderServiceClient.prototype.listOrders =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/da.proto.OrderService/ListOrders',
      request,
      metadata || {},
      methodDescriptor_OrderService_ListOrders,
      callback);
};


/**
 * @param {!proto.da.proto.ListOrdersRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.da.proto.ListOrdersResponse>}
 *     Promise that resolves to the response
 */
proto.da.proto.OrderServicePromiseClient.prototype.listOrders =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/da.proto.OrderService/ListOrders',
      request,
      metadata || {},
      methodDescriptor_OrderService_ListOrders);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.da.proto.UpdateOrderRequest,
 *   !proto.da.proto.UpdateOrderResponse>}
 */
const methodDescriptor_OrderService_UpdateOrder = new grpc.web.MethodDescriptor(
  '/da.proto.OrderService/UpdateOrder',
  grpc.web.MethodType.UNARY,
  proto.da.proto.UpdateOrderRequest,
  proto.da.proto.UpdateOrderResponse,
  /**
   * @param {!proto.da.proto.UpdateOrderRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.da.proto.UpdateOrderResponse.deserializeBinary
);


/**
 * @param {!proto.da.proto.UpdateOrderRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.da.proto.UpdateOrderResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.da.proto.UpdateOrderResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.da.proto.OrderServiceClient.prototype.updateOrder =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/da.proto.OrderService/UpdateOrder',
      request,
      metadata || {},
      methodDescriptor_OrderService_UpdateOrder,
      callback);
};


/**
 * @param {!proto.da.proto.UpdateOrderRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.da.proto.UpdateOrderResponse>}
 *     Promise that resolves to the response
 */
proto.da.proto.OrderServicePromiseClient.prototype.updateOrder =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/da.proto.OrderService/UpdateOrder',
      request,
      metadata || {},
      methodDescriptor_OrderService_UpdateOrder);
};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.da.proto.CategoryServiceClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.da.proto.CategoryServicePromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.da.proto.CreateCategoryRequest,
 *   !proto.da.proto.CreateCategoryResponse>}
 */
const methodDescriptor_CategoryService_CreateCategory = new grpc.web.MethodDescriptor(
  '/da.proto.CategoryService/CreateCategory',
  grpc.web.MethodType.UNARY,
  proto.da.proto.CreateCategoryRequest,
  proto.da.proto.CreateCategoryResponse,
  /**
   * @param {!proto.da.proto.CreateCategoryRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.da.proto.CreateCategoryResponse.deserializeBinary
);


/**
 * @param {!proto.da.proto.CreateCategoryRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.da.proto.CreateCategoryResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.da.proto.CreateCategoryResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.da.proto.CategoryServiceClient.prototype.createCategory =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/da.proto.CategoryService/CreateCategory',
      request,
      metadata || {},
      methodDescriptor_CategoryService_CreateCategory,
      callback);
};


/**
 * @param {!proto.da.proto.CreateCategoryRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.da.proto.CreateCategoryResponse>}
 *     Promise that resolves to the response
 */
proto.da.proto.CategoryServicePromiseClient.prototype.createCategory =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/da.proto.CategoryService/CreateCategory',
      request,
      metadata || {},
      methodDescriptor_CategoryService_CreateCategory);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.da.proto.ReadCategoryRequest,
 *   !proto.da.proto.ReadCategoryResponse>}
 */
const methodDescriptor_CategoryService_ReadCategory = new grpc.web.MethodDescriptor(
  '/da.proto.CategoryService/ReadCategory',
  grpc.web.MethodType.UNARY,
  proto.da.proto.ReadCategoryRequest,
  proto.da.proto.ReadCategoryResponse,
  /**
   * @param {!proto.da.proto.ReadCategoryRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.da.proto.ReadCategoryResponse.deserializeBinary
);


/**
 * @param {!proto.da.proto.ReadCategoryRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.da.proto.ReadCategoryResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.da.proto.ReadCategoryResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.da.proto.CategoryServiceClient.prototype.readCategory =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/da.proto.CategoryService/ReadCategory',
      request,
      metadata || {},
      methodDescriptor_CategoryService_ReadCategory,
      callback);
};


/**
 * @param {!proto.da.proto.ReadCategoryRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.da.proto.ReadCategoryResponse>}
 *     Promise that resolves to the response
 */
proto.da.proto.CategoryServicePromiseClient.prototype.readCategory =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/da.proto.CategoryService/ReadCategory',
      request,
      metadata || {},
      methodDescriptor_CategoryService_ReadCategory);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.da.proto.ListCategoriesRequest,
 *   !proto.da.proto.ListCategoriesResponse>}
 */
const methodDescriptor_CategoryService_ListCategories = new grpc.web.MethodDescriptor(
  '/da.proto.CategoryService/ListCategories',
  grpc.web.MethodType.UNARY,
  proto.da.proto.ListCategoriesRequest,
  proto.da.proto.ListCategoriesResponse,
  /**
   * @param {!proto.da.proto.ListCategoriesRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.da.proto.ListCategoriesResponse.deserializeBinary
);


/**
 * @param {!proto.da.proto.ListCategoriesRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.da.proto.ListCategoriesResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.da.proto.ListCategoriesResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.da.proto.CategoryServiceClient.prototype.listCategories =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/da.proto.CategoryService/ListCategories',
      request,
      metadata || {},
      methodDescriptor_CategoryService_ListCategories,
      callback);
};


/**
 * @param {!proto.da.proto.ListCategoriesRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.da.proto.ListCategoriesResponse>}
 *     Promise that resolves to the response
 */
proto.da.proto.CategoryServicePromiseClient.prototype.listCategories =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/da.proto.CategoryService/ListCategories',
      request,
      metadata || {},
      methodDescriptor_CategoryService_ListCategories);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.da.proto.UpdateCategoryRequest,
 *   !proto.da.proto.UpdateCategoryResponse>}
 */
const methodDescriptor_CategoryService_UpdateCategory = new grpc.web.MethodDescriptor(
  '/da.proto.CategoryService/UpdateCategory',
  grpc.web.MethodType.UNARY,
  proto.da.proto.UpdateCategoryRequest,
  proto.da.proto.UpdateCategoryResponse,
  /**
   * @param {!proto.da.proto.UpdateCategoryRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.da.proto.UpdateCategoryResponse.deserializeBinary
);


/**
 * @param {!proto.da.proto.UpdateCategoryRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.da.proto.UpdateCategoryResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.da.proto.UpdateCategoryResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.da.proto.CategoryServiceClient.prototype.updateCategory =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/da.proto.CategoryService/UpdateCategory',
      request,
      metadata || {},
      methodDescriptor_CategoryService_UpdateCategory,
      callback);
};


/**
 * @param {!proto.da.proto.UpdateCategoryRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.da.proto.UpdateCategoryResponse>}
 *     Promise that resolves to the response
 */
proto.da.proto.CategoryServicePromiseClient.prototype.updateCategory =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/da.proto.CategoryService/UpdateCategory',
      request,
      metadata || {},
      methodDescriptor_CategoryService_UpdateCategory);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.da.proto.DeleteCategoryRequest,
 *   !proto.da.proto.DeleteCategoryResponse>}
 */
const methodDescriptor_CategoryService_DeleteCategory = new grpc.web.MethodDescriptor(
  '/da.proto.CategoryService/DeleteCategory',
  grpc.web.MethodType.UNARY,
  proto.da.proto.DeleteCategoryRequest,
  proto.da.proto.DeleteCategoryResponse,
  /**
   * @param {!proto.da.proto.DeleteCategoryRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.da.proto.DeleteCategoryResponse.deserializeBinary
);


/**
 * @param {!proto.da.proto.DeleteCategoryRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.da.proto.DeleteCategoryResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.da.proto.DeleteCategoryResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.da.proto.CategoryServiceClient.prototype.deleteCategory =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/da.proto.CategoryService/DeleteCategory',
      request,
      metadata || {},
      methodDescriptor_CategoryService_DeleteCategory,
      callback);
};


/**
 * @param {!proto.da.proto.DeleteCategoryRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.da.proto.DeleteCategoryResponse>}
 *     Promise that resolves to the response
 */
proto.da.proto.CategoryServicePromiseClient.prototype.deleteCategory =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/da.proto.CategoryService/DeleteCategory',
      request,
      metadata || {},
      methodDescriptor_CategoryService_DeleteCategory);
};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.da.proto.CatalogServiceClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.da.proto.CatalogServicePromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.da.proto.CreateCatalogRequest,
 *   !proto.da.proto.CreateCatalogResponse>}
 */
const methodDescriptor_CatalogService_CreateCatalog = new grpc.web.MethodDescriptor(
  '/da.proto.CatalogService/CreateCatalog',
  grpc.web.MethodType.UNARY,
  proto.da.proto.CreateCatalogRequest,
  proto.da.proto.CreateCatalogResponse,
  /**
   * @param {!proto.da.proto.CreateCatalogRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.da.proto.CreateCatalogResponse.deserializeBinary
);


/**
 * @param {!proto.da.proto.CreateCatalogRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.da.proto.CreateCatalogResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.da.proto.CreateCatalogResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.da.proto.CatalogServiceClient.prototype.createCatalog =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/da.proto.CatalogService/CreateCatalog',
      request,
      metadata || {},
      methodDescriptor_CatalogService_CreateCatalog,
      callback);
};


/**
 * @param {!proto.da.proto.CreateCatalogRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.da.proto.CreateCatalogResponse>}
 *     Promise that resolves to the response
 */
proto.da.proto.CatalogServicePromiseClient.prototype.createCatalog =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/da.proto.CatalogService/CreateCatalog',
      request,
      metadata || {},
      methodDescriptor_CatalogService_CreateCatalog);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.da.proto.ReadCatalogRequest,
 *   !proto.da.proto.ReadCatalogResponse>}
 */
const methodDescriptor_CatalogService_ReadCatalog = new grpc.web.MethodDescriptor(
  '/da.proto.CatalogService/ReadCatalog',
  grpc.web.MethodType.UNARY,
  proto.da.proto.ReadCatalogRequest,
  proto.da.proto.ReadCatalogResponse,
  /**
   * @param {!proto.da.proto.ReadCatalogRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.da.proto.ReadCatalogResponse.deserializeBinary
);


/**
 * @param {!proto.da.proto.ReadCatalogRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.da.proto.ReadCatalogResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.da.proto.ReadCatalogResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.da.proto.CatalogServiceClient.prototype.readCatalog =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/da.proto.CatalogService/ReadCatalog',
      request,
      metadata || {},
      methodDescriptor_CatalogService_ReadCatalog,
      callback);
};


/**
 * @param {!proto.da.proto.ReadCatalogRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.da.proto.ReadCatalogResponse>}
 *     Promise that resolves to the response
 */
proto.da.proto.CatalogServicePromiseClient.prototype.readCatalog =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/da.proto.CatalogService/ReadCatalog',
      request,
      metadata || {},
      methodDescriptor_CatalogService_ReadCatalog);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.da.proto.ListCatalogsRequest,
 *   !proto.da.proto.ListCatalogsResponse>}
 */
const methodDescriptor_CatalogService_ListCatalogs = new grpc.web.MethodDescriptor(
  '/da.proto.CatalogService/ListCatalogs',
  grpc.web.MethodType.UNARY,
  proto.da.proto.ListCatalogsRequest,
  proto.da.proto.ListCatalogsResponse,
  /**
   * @param {!proto.da.proto.ListCatalogsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.da.proto.ListCatalogsResponse.deserializeBinary
);


/**
 * @param {!proto.da.proto.ListCatalogsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.da.proto.ListCatalogsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.da.proto.ListCatalogsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.da.proto.CatalogServiceClient.prototype.listCatalogs =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/da.proto.CatalogService/ListCatalogs',
      request,
      metadata || {},
      methodDescriptor_CatalogService_ListCatalogs,
      callback);
};


/**
 * @param {!proto.da.proto.ListCatalogsRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.da.proto.ListCatalogsResponse>}
 *     Promise that resolves to the response
 */
proto.da.proto.CatalogServicePromiseClient.prototype.listCatalogs =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/da.proto.CatalogService/ListCatalogs',
      request,
      metadata || {},
      methodDescriptor_CatalogService_ListCatalogs);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.da.proto.UpdateCatalogRequest,
 *   !proto.da.proto.UpdateCatalogResponse>}
 */
const methodDescriptor_CatalogService_UpdateCatalog = new grpc.web.MethodDescriptor(
  '/da.proto.CatalogService/UpdateCatalog',
  grpc.web.MethodType.UNARY,
  proto.da.proto.UpdateCatalogRequest,
  proto.da.proto.UpdateCatalogResponse,
  /**
   * @param {!proto.da.proto.UpdateCatalogRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.da.proto.UpdateCatalogResponse.deserializeBinary
);


/**
 * @param {!proto.da.proto.UpdateCatalogRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.da.proto.UpdateCatalogResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.da.proto.UpdateCatalogResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.da.proto.CatalogServiceClient.prototype.updateCatalog =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/da.proto.CatalogService/UpdateCatalog',
      request,
      metadata || {},
      methodDescriptor_CatalogService_UpdateCatalog,
      callback);
};


/**
 * @param {!proto.da.proto.UpdateCatalogRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.da.proto.UpdateCatalogResponse>}
 *     Promise that resolves to the response
 */
proto.da.proto.CatalogServicePromiseClient.prototype.updateCatalog =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/da.proto.CatalogService/UpdateCatalog',
      request,
      metadata || {},
      methodDescriptor_CatalogService_UpdateCatalog);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.da.proto.DeleteCatalogRequest,
 *   !proto.da.proto.DeleteCatalogResponse>}
 */
const methodDescriptor_CatalogService_DeleteCatalog = new grpc.web.MethodDescriptor(
  '/da.proto.CatalogService/DeleteCatalog',
  grpc.web.MethodType.UNARY,
  proto.da.proto.DeleteCatalogRequest,
  proto.da.proto.DeleteCatalogResponse,
  /**
   * @param {!proto.da.proto.DeleteCatalogRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.da.proto.DeleteCatalogResponse.deserializeBinary
);


/**
 * @param {!proto.da.proto.DeleteCatalogRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.da.proto.DeleteCatalogResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.da.proto.DeleteCatalogResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.da.proto.CatalogServiceClient.prototype.deleteCatalog =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/da.proto.CatalogService/DeleteCatalog',
      request,
      metadata || {},
      methodDescriptor_CatalogService_DeleteCatalog,
      callback);
};


/**
 * @param {!proto.da.proto.DeleteCatalogRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.da.proto.DeleteCatalogResponse>}
 *     Promise that resolves to the response
 */
proto.da.proto.CatalogServicePromiseClient.prototype.deleteCatalog =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/da.proto.CatalogService/DeleteCatalog',
      request,
      metadata || {},
      methodDescriptor_CatalogService_DeleteCatalog);
};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.da.proto.TaxonServiceClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.da.proto.TaxonServicePromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.da.proto.CreateTaxonRequest,
 *   !proto.da.proto.CreateTaxonResponse>}
 */
const methodDescriptor_TaxonService_CreateTaxon = new grpc.web.MethodDescriptor(
  '/da.proto.TaxonService/CreateTaxon',
  grpc.web.MethodType.UNARY,
  proto.da.proto.CreateTaxonRequest,
  proto.da.proto.CreateTaxonResponse,
  /**
   * @param {!proto.da.proto.CreateTaxonRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.da.proto.CreateTaxonResponse.deserializeBinary
);


/**
 * @param {!proto.da.proto.CreateTaxonRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.da.proto.CreateTaxonResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.da.proto.CreateTaxonResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.da.proto.TaxonServiceClient.prototype.createTaxon =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/da.proto.TaxonService/CreateTaxon',
      request,
      metadata || {},
      methodDescriptor_TaxonService_CreateTaxon,
      callback);
};


/**
 * @param {!proto.da.proto.CreateTaxonRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.da.proto.CreateTaxonResponse>}
 *     Promise that resolves to the response
 */
proto.da.proto.TaxonServicePromiseClient.prototype.createTaxon =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/da.proto.TaxonService/CreateTaxon',
      request,
      metadata || {},
      methodDescriptor_TaxonService_CreateTaxon);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.da.proto.ReadTaxonRequest,
 *   !proto.da.proto.ReadTaxonResponse>}
 */
const methodDescriptor_TaxonService_ReadTaxon = new grpc.web.MethodDescriptor(
  '/da.proto.TaxonService/ReadTaxon',
  grpc.web.MethodType.UNARY,
  proto.da.proto.ReadTaxonRequest,
  proto.da.proto.ReadTaxonResponse,
  /**
   * @param {!proto.da.proto.ReadTaxonRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.da.proto.ReadTaxonResponse.deserializeBinary
);


/**
 * @param {!proto.da.proto.ReadTaxonRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.da.proto.ReadTaxonResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.da.proto.ReadTaxonResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.da.proto.TaxonServiceClient.prototype.readTaxon =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/da.proto.TaxonService/ReadTaxon',
      request,
      metadata || {},
      methodDescriptor_TaxonService_ReadTaxon,
      callback);
};


/**
 * @param {!proto.da.proto.ReadTaxonRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.da.proto.ReadTaxonResponse>}
 *     Promise that resolves to the response
 */
proto.da.proto.TaxonServicePromiseClient.prototype.readTaxon =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/da.proto.TaxonService/ReadTaxon',
      request,
      metadata || {},
      methodDescriptor_TaxonService_ReadTaxon);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.da.proto.UpdateTaxonRequest,
 *   !proto.da.proto.UpdateTaxonResponse>}
 */
const methodDescriptor_TaxonService_UpdateTaxon = new grpc.web.MethodDescriptor(
  '/da.proto.TaxonService/UpdateTaxon',
  grpc.web.MethodType.UNARY,
  proto.da.proto.UpdateTaxonRequest,
  proto.da.proto.UpdateTaxonResponse,
  /**
   * @param {!proto.da.proto.UpdateTaxonRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.da.proto.UpdateTaxonResponse.deserializeBinary
);


/**
 * @param {!proto.da.proto.UpdateTaxonRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.da.proto.UpdateTaxonResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.da.proto.UpdateTaxonResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.da.proto.TaxonServiceClient.prototype.updateTaxon =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/da.proto.TaxonService/UpdateTaxon',
      request,
      metadata || {},
      methodDescriptor_TaxonService_UpdateTaxon,
      callback);
};


/**
 * @param {!proto.da.proto.UpdateTaxonRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.da.proto.UpdateTaxonResponse>}
 *     Promise that resolves to the response
 */
proto.da.proto.TaxonServicePromiseClient.prototype.updateTaxon =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/da.proto.TaxonService/UpdateTaxon',
      request,
      metadata || {},
      methodDescriptor_TaxonService_UpdateTaxon);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.da.proto.DeleteTaxonRequest,
 *   !proto.da.proto.DeleteTaxonResponse>}
 */
const methodDescriptor_TaxonService_DeleteTaxon = new grpc.web.MethodDescriptor(
  '/da.proto.TaxonService/DeleteTaxon',
  grpc.web.MethodType.UNARY,
  proto.da.proto.DeleteTaxonRequest,
  proto.da.proto.DeleteTaxonResponse,
  /**
   * @param {!proto.da.proto.DeleteTaxonRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.da.proto.DeleteTaxonResponse.deserializeBinary
);


/**
 * @param {!proto.da.proto.DeleteTaxonRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.da.proto.DeleteTaxonResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.da.proto.DeleteTaxonResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.da.proto.TaxonServiceClient.prototype.deleteTaxon =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/da.proto.TaxonService/DeleteTaxon',
      request,
      metadata || {},
      methodDescriptor_TaxonService_DeleteTaxon,
      callback);
};


/**
 * @param {!proto.da.proto.DeleteTaxonRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.da.proto.DeleteTaxonResponse>}
 *     Promise that resolves to the response
 */
proto.da.proto.TaxonServicePromiseClient.prototype.deleteTaxon =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/da.proto.TaxonService/DeleteTaxon',
      request,
      metadata || {},
      methodDescriptor_TaxonService_DeleteTaxon);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.da.proto.ListTaxonRequest,
 *   !proto.da.proto.ListTaxonResponse>}
 */
const methodDescriptor_TaxonService_ListTaxon = new grpc.web.MethodDescriptor(
  '/da.proto.TaxonService/ListTaxon',
  grpc.web.MethodType.UNARY,
  proto.da.proto.ListTaxonRequest,
  proto.da.proto.ListTaxonResponse,
  /**
   * @param {!proto.da.proto.ListTaxonRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.da.proto.ListTaxonResponse.deserializeBinary
);


/**
 * @param {!proto.da.proto.ListTaxonRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.da.proto.ListTaxonResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.da.proto.ListTaxonResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.da.proto.TaxonServiceClient.prototype.listTaxon =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/da.proto.TaxonService/ListTaxon',
      request,
      metadata || {},
      methodDescriptor_TaxonService_ListTaxon,
      callback);
};


/**
 * @param {!proto.da.proto.ListTaxonRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.da.proto.ListTaxonResponse>}
 *     Promise that resolves to the response
 */
proto.da.proto.TaxonServicePromiseClient.prototype.listTaxon =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/da.proto.TaxonService/ListTaxon',
      request,
      metadata || {},
      methodDescriptor_TaxonService_ListTaxon);
};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.da.proto.ConditionServiceClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.da.proto.ConditionServicePromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.da.proto.ListConditionsRequest,
 *   !proto.da.proto.ListConditionsResponse>}
 */
const methodDescriptor_ConditionService_ListConditions = new grpc.web.MethodDescriptor(
  '/da.proto.ConditionService/ListConditions',
  grpc.web.MethodType.UNARY,
  proto.da.proto.ListConditionsRequest,
  proto.da.proto.ListConditionsResponse,
  /**
   * @param {!proto.da.proto.ListConditionsRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.da.proto.ListConditionsResponse.deserializeBinary
);


/**
 * @param {!proto.da.proto.ListConditionsRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.da.proto.ListConditionsResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.da.proto.ListConditionsResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.da.proto.ConditionServiceClient.prototype.listConditions =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/da.proto.ConditionService/ListConditions',
      request,
      metadata || {},
      methodDescriptor_ConditionService_ListConditions,
      callback);
};


/**
 * @param {!proto.da.proto.ListConditionsRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.da.proto.ListConditionsResponse>}
 *     Promise that resolves to the response
 */
proto.da.proto.ConditionServicePromiseClient.prototype.listConditions =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/da.proto.ConditionService/ListConditions',
      request,
      metadata || {},
      methodDescriptor_ConditionService_ListConditions);
};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.da.proto.AvailabilityServiceClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.da.proto.AvailabilityServicePromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.da.proto.ListAvailabilityRequest,
 *   !proto.da.proto.ListAvailabilityResponse>}
 */
const methodDescriptor_AvailabilityService_ListAvailability = new grpc.web.MethodDescriptor(
  '/da.proto.AvailabilityService/ListAvailability',
  grpc.web.MethodType.UNARY,
  proto.da.proto.ListAvailabilityRequest,
  proto.da.proto.ListAvailabilityResponse,
  /**
   * @param {!proto.da.proto.ListAvailabilityRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.da.proto.ListAvailabilityResponse.deserializeBinary
);


/**
 * @param {!proto.da.proto.ListAvailabilityRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.da.proto.ListAvailabilityResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.da.proto.ListAvailabilityResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.da.proto.AvailabilityServiceClient.prototype.listAvailability =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/da.proto.AvailabilityService/ListAvailability',
      request,
      metadata || {},
      methodDescriptor_AvailabilityService_ListAvailability,
      callback);
};


/**
 * @param {!proto.da.proto.ListAvailabilityRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.da.proto.ListAvailabilityResponse>}
 *     Promise that resolves to the response
 */
proto.da.proto.AvailabilityServicePromiseClient.prototype.listAvailability =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/da.proto.AvailabilityService/ListAvailability',
      request,
      metadata || {},
      methodDescriptor_AvailabilityService_ListAvailability);
};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.da.proto.CustomerServiceClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @param {string} hostname
 * @param {?Object} credentials
 * @param {?grpc.web.ClientOptions} options
 * @constructor
 * @struct
 * @final
 */
proto.da.proto.CustomerServicePromiseClient =
    function(hostname, credentials, options) {
  if (!options) options = {};
  options.format = 'text';

  /**
   * @private @const {!grpc.web.GrpcWebClientBase} The client
   */
  this.client_ = new grpc.web.GrpcWebClientBase(options);

  /**
   * @private @const {string} The hostname
   */
  this.hostname_ = hostname.replace(/\/+$/, '');

};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.da.proto.CreateCustomerRequest,
 *   !proto.da.proto.CreateCustomerResponse>}
 */
const methodDescriptor_CustomerService_CreateCustomer = new grpc.web.MethodDescriptor(
  '/da.proto.CustomerService/CreateCustomer',
  grpc.web.MethodType.UNARY,
  proto.da.proto.CreateCustomerRequest,
  proto.da.proto.CreateCustomerResponse,
  /**
   * @param {!proto.da.proto.CreateCustomerRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.da.proto.CreateCustomerResponse.deserializeBinary
);


/**
 * @param {!proto.da.proto.CreateCustomerRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.da.proto.CreateCustomerResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.da.proto.CreateCustomerResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.da.proto.CustomerServiceClient.prototype.createCustomer =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/da.proto.CustomerService/CreateCustomer',
      request,
      metadata || {},
      methodDescriptor_CustomerService_CreateCustomer,
      callback);
};


/**
 * @param {!proto.da.proto.CreateCustomerRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.da.proto.CreateCustomerResponse>}
 *     Promise that resolves to the response
 */
proto.da.proto.CustomerServicePromiseClient.prototype.createCustomer =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/da.proto.CustomerService/CreateCustomer',
      request,
      metadata || {},
      methodDescriptor_CustomerService_CreateCustomer);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.da.proto.ReadCustomerRequest,
 *   !proto.da.proto.ReadCustomerResponse>}
 */
const methodDescriptor_CustomerService_ReadCustomer = new grpc.web.MethodDescriptor(
  '/da.proto.CustomerService/ReadCustomer',
  grpc.web.MethodType.UNARY,
  proto.da.proto.ReadCustomerRequest,
  proto.da.proto.ReadCustomerResponse,
  /**
   * @param {!proto.da.proto.ReadCustomerRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.da.proto.ReadCustomerResponse.deserializeBinary
);


/**
 * @param {!proto.da.proto.ReadCustomerRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.da.proto.ReadCustomerResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.da.proto.ReadCustomerResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.da.proto.CustomerServiceClient.prototype.readCustomer =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/da.proto.CustomerService/ReadCustomer',
      request,
      metadata || {},
      methodDescriptor_CustomerService_ReadCustomer,
      callback);
};


/**
 * @param {!proto.da.proto.ReadCustomerRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.da.proto.ReadCustomerResponse>}
 *     Promise that resolves to the response
 */
proto.da.proto.CustomerServicePromiseClient.prototype.readCustomer =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/da.proto.CustomerService/ReadCustomer',
      request,
      metadata || {},
      methodDescriptor_CustomerService_ReadCustomer);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.da.proto.ListCustomersRequest,
 *   !proto.da.proto.ListCustomersResponse>}
 */
const methodDescriptor_CustomerService_ListCustomers = new grpc.web.MethodDescriptor(
  '/da.proto.CustomerService/ListCustomers',
  grpc.web.MethodType.UNARY,
  proto.da.proto.ListCustomersRequest,
  proto.da.proto.ListCustomersResponse,
  /**
   * @param {!proto.da.proto.ListCustomersRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.da.proto.ListCustomersResponse.deserializeBinary
);


/**
 * @param {!proto.da.proto.ListCustomersRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.da.proto.ListCustomersResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.da.proto.ListCustomersResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.da.proto.CustomerServiceClient.prototype.listCustomers =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/da.proto.CustomerService/ListCustomers',
      request,
      metadata || {},
      methodDescriptor_CustomerService_ListCustomers,
      callback);
};


/**
 * @param {!proto.da.proto.ListCustomersRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.da.proto.ListCustomersResponse>}
 *     Promise that resolves to the response
 */
proto.da.proto.CustomerServicePromiseClient.prototype.listCustomers =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/da.proto.CustomerService/ListCustomers',
      request,
      metadata || {},
      methodDescriptor_CustomerService_ListCustomers);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.da.proto.UpdateCustomerRequest,
 *   !proto.da.proto.UpdateCustomerResponse>}
 */
const methodDescriptor_CustomerService_UpdateCustomer = new grpc.web.MethodDescriptor(
  '/da.proto.CustomerService/UpdateCustomer',
  grpc.web.MethodType.UNARY,
  proto.da.proto.UpdateCustomerRequest,
  proto.da.proto.UpdateCustomerResponse,
  /**
   * @param {!proto.da.proto.UpdateCustomerRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.da.proto.UpdateCustomerResponse.deserializeBinary
);


/**
 * @param {!proto.da.proto.UpdateCustomerRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.da.proto.UpdateCustomerResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.da.proto.UpdateCustomerResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.da.proto.CustomerServiceClient.prototype.updateCustomer =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/da.proto.CustomerService/UpdateCustomer',
      request,
      metadata || {},
      methodDescriptor_CustomerService_UpdateCustomer,
      callback);
};


/**
 * @param {!proto.da.proto.UpdateCustomerRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.da.proto.UpdateCustomerResponse>}
 *     Promise that resolves to the response
 */
proto.da.proto.CustomerServicePromiseClient.prototype.updateCustomer =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/da.proto.CustomerService/UpdateCustomer',
      request,
      metadata || {},
      methodDescriptor_CustomerService_UpdateCustomer);
};


/**
 * @const
 * @type {!grpc.web.MethodDescriptor<
 *   !proto.da.proto.DeleteCustomerRequest,
 *   !proto.da.proto.DeleteCustomerResponse>}
 */
const methodDescriptor_CustomerService_DeleteCustomer = new grpc.web.MethodDescriptor(
  '/da.proto.CustomerService/DeleteCustomer',
  grpc.web.MethodType.UNARY,
  proto.da.proto.DeleteCustomerRequest,
  proto.da.proto.DeleteCustomerResponse,
  /**
   * @param {!proto.da.proto.DeleteCustomerRequest} request
   * @return {!Uint8Array}
   */
  function(request) {
    return request.serializeBinary();
  },
  proto.da.proto.DeleteCustomerResponse.deserializeBinary
);


/**
 * @param {!proto.da.proto.DeleteCustomerRequest} request The
 *     request proto
 * @param {?Object<string, string>} metadata User defined
 *     call metadata
 * @param {function(?grpc.web.RpcError, ?proto.da.proto.DeleteCustomerResponse)}
 *     callback The callback function(error, response)
 * @return {!grpc.web.ClientReadableStream<!proto.da.proto.DeleteCustomerResponse>|undefined}
 *     The XHR Node Readable Stream
 */
proto.da.proto.CustomerServiceClient.prototype.deleteCustomer =
    function(request, metadata, callback) {
  return this.client_.rpcCall(this.hostname_ +
      '/da.proto.CustomerService/DeleteCustomer',
      request,
      metadata || {},
      methodDescriptor_CustomerService_DeleteCustomer,
      callback);
};


/**
 * @param {!proto.da.proto.DeleteCustomerRequest} request The
 *     request proto
 * @param {?Object<string, string>=} metadata User defined
 *     call metadata
 * @return {!Promise<!proto.da.proto.DeleteCustomerResponse>}
 *     Promise that resolves to the response
 */
proto.da.proto.CustomerServicePromiseClient.prototype.deleteCustomer =
    function(request, metadata) {
  return this.client_.unaryCall(this.hostname_ +
      '/da.proto.CustomerService/DeleteCustomer',
      request,
      metadata || {},
      methodDescriptor_CustomerService_DeleteCustomer);
};


module.exports = proto.da.proto;

