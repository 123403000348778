import { SELECT_SHOP } from "../actions/actions";

const SelectShop = (state: string = "", action: any) => {
  switch (action.type) {
    case SELECT_SHOP:
      return action.payload;
    default:
      return state;
  }
};

export default SelectShop;
